import contentDataset from "../components/Content/DataModels/ContentIds.json";

export const getContentId = (section) => {
  if (
    process.env.REACT_APP_BEE_ENDPOINT.includes(
      "sandbox-bee.netzerocompass.com"
    )
  ) {
    return contentDataset?.find((data) => data?.section === section)
      ?.sandboxContentId;
  } else {
    return contentDataset?.find((data) => data?.section === section)
      ?.productionContentId;
  }
};
