import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { formatNumber } from "components/Organization/OrganizationDetail/Forecasting/helper";
import { Field, FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { post } from "utils/DeApi";
import * as yup from "yup";

const ManageReductionPotential = ({
  title,
  activityTypes,
  activityTypesPercentages,
  onManageReductionsUpdated,
  id,
  year,
  parentCategory,
  organization,
  decarbEmissions,
  showActivityTypes,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);

  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    reductionPotentialScope1: yup
      .number()
      .label("Reduction Potential For Scope 1")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`),
    reductionPotentialScope2: yup
      .number()
      .label("Reduction Potential For Scope 2")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`),
    reductionPotentialScope3: yup
      .number()
      .label("Reduction Potential For Scope 3")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`),
    reductions: yup.array().of(
      yup.object().shape({
        reductionPercentage: yup
          .number()
          .label("Reduction Percentage")
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          ),
      })
    ),
  });

  const updateReduction = (values) => {
    setIsLoading(true);
    setError(null);
    const reductions = values.reductions.map((item) => ({
      activityTypeId: item.activityTypeId,
      reductionPercentage: item.reductionPercentage || 0,
    }));

    reductions.push(
      {
        scope: 1,
        reductionPercentage: values.reductionPotentialScope1 || 0,
      },
      {
        scope: 2,
        reductionPercentage: values.reductionPotentialScope2 || 0,
      },
      {
        scope: 3,
        reductionPercentage: values.reductionPotentialScope3 || 0,
      }
    );
    const data = {
      opportunityGroupId: id,
      reductions: reductions,
      yearEnded: values.yearEnded + "-12-31",
    };

    const organizationPromise = post(
      `organizations/${organization?.id}/categories/reduction`,
      data
    );

    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageReductionsUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);
  return (
    <>
      <Button size="sm" onClick={handleShow}>
        Manage
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title className="fs-4">Manage Reduction Potential</Modal.Title>
        </Modal.Header>
        {error && <ErrorHandler error={error} />}
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateReduction(values)}
          enableReinitialize={true}
          initialValues={{
            reductions: [...activityTypes].map((item) => {
              const reductionPercent = formatNumber(
                activityTypesPercentages?.find((activity) => {
                  return (
                    activity?.activityType?.id === item.id &&
                    activity.yearEnded.includes(year)
                  );
                })?.reductionPercentage || 0
              );
              return {
                action: `S${item.scope}: ${
                  item?.categoryNumber ? `${item?.categoryNumber}. ` : ""
                }${item.label}`,
                reductionPercentage: reductionPercent,
                scope: item.scope,
                activityTypeId: item.id,
              };
            }),
            scopePreference: 1,
            yearEnded: year,
            reductionPotentialScope1: formatNumber(
              activityTypesPercentages?.find((activity) => {
                return (
                  activity?.scope === 1 && activity.yearEnded.includes(year)
                );
              })?.reductionPercentage || 0
            ),
            reductionPotentialScope2: formatNumber(
              activityTypesPercentages?.find((activity) => {
                return (
                  activity?.scope === 2 && activity.yearEnded.includes(year)
                );
              })?.reductionPercentage || 0
            ),
            reductionPotentialScope3: formatNumber(
              activityTypesPercentages?.find((activity) => {
                return (
                  activity?.scope === 3 && activity.yearEnded.includes(year)
                );
              })?.reductionPercentage || 0
            ),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="">
              <Modal.Body>
                <h2 className="mb-3">
                  {parentCategory} - {title}
                </h2>
                <Row className="mt-3">
                  <Form.Label>Inventory Year</Form.Label>
                  <Form.Group
                    controlId={`yearEnded`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      as={Form.Control}
                      type={"number"}
                      name={`yearEnded`}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.yearEnded}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Label>Select Impacted Scope</Form.Label>
                  <Form.Group className="mb-2" as={Col} xs={12}>
                    <Field
                      as={Form.Select}
                      name="scopePreference"
                      value={values.scopePreference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {["Scope 1", "Scope 2", "Scope 3"].map((scope, index) => (
                        <option key={scope} value={index + 1}>
                          {scope}
                        </option>
                      ))}
                    </Field>
                    <Form.Text>
                      Total Emissions for Scope {values.scopePreference}:{" "}
                      {formatNumber(
                        parseFloat(
                          decarbEmissions?.find(
                            (item) => item?.scope == values.scopePreference
                          )?.emissions
                        ).toFixed(),
                        0
                      ) || 0}{" "}
                      tCO<sub>2</sub>e
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Label>
                    Reduction Potential (%) for Scope {values.scopePreference}
                  </Form.Label>
                  <Form.Group
                    controlId={`reductionPotentialScope${values.scopePreference}`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Row>
                      <Col xs={10} className="d-flex align-items-center">
                        <Field
                          as={Form.Range}
                          min={0}
                          max={100}
                          step={5}
                          type={"number"}
                          disabled={showActivityTypes}
                          name={`reductionPotentialScope${values.scopePreference}`}
                          onChange={(ev) => {
                            const value = ev.target.valueAsNumber;
                            setFieldValue(
                              `reductionPotentialScope${values.scopePreference}`,
                              !isNaN(value) ? value : ""
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col xs={2} className="px-0">
                        <Field
                          type={"number"}
                          name={`reductionPotentialScope${values.scopePreference}`}
                          as={Form.Control}
                          disabled
                        />
                      </Col>
                    </Row>

                    <Form.Control.Feedback type="invalid">
                      {
                        errors[
                          `reductionPotentialScope${values.scopePreference}`
                        ]
                      }
                    </Form.Control.Feedback>
                    <Form.Text>
                      Reduction Potential in tCO<sub>2</sub>e:{" "}
                      {formatNumber(
                        parseFloat(
                          (parseFloat(
                            decarbEmissions?.find(
                              (item) => item?.scope == values.scopePreference
                            )?.emissions
                          ) /
                            100) *
                            values[
                              `reductionPotentialScope${values.scopePreference}`
                            ]
                        ).toFixed(),
                        0
                      ) || 0}{" "}
                      tCO<sub>2</sub>e
                    </Form.Text>
                  </Form.Group>
                </Row>

                {showActivityTypes && (
                  <>
                    <Row className="mb-1 mt-3">
                      <Col xs={9}>Activity Types & Emissions (tCO2e)</Col>
                      <Col xs={3} className="ps-0">
                        Reduction (%)
                      </Col>
                    </Row>
                    <FieldArray
                      name="reductions"
                      render={(arrayHelpers) => (
                        <>
                          {values.reductions.map((r, index) => {
                            if (r.scope != values.scopePreference) return null;
                            return (
                              <Row key={index}>
                                <Form.Group
                                  controlId={`reductions[${index}].action`}
                                  className="mb-2"
                                  as={Col}
                                  xs={9}
                                >
                                  <Field
                                    name={`reductions[${index}].action`}
                                    as={Form.Control}
                                    type="text"
                                    disabled={true}
                                  />
                                  <Form.Text>
                                    Total Emissions:{" "}
                                    {formatNumber(
                                      parseFloat(
                                        decarbEmissions?.find(
                                          (item) =>
                                            item?.activityType?.id ===
                                            r.activityTypeId
                                        )?.emissions
                                      ).toFixed(),
                                      0
                                    ) || 0}{" "}
                                    tCO<sub>2</sub>e
                                  </Form.Text>
                                </Form.Group>
                                <Form.Group
                                  controlId={`reductions[${index}].reductionPercentage`}
                                  className="mb-2 ps-0"
                                  as={Col}
                                  xs={3}
                                >
                                  <Row>
                                    <Col
                                      xs={8}
                                      className="d-flex align-items-center"
                                    >
                                      <Field
                                        type="number"
                                        name={`reductions[${index}].reductionPercentage`}
                                        as={Form.Range}
                                        min={0}
                                        max={100}
                                        step={5}
                                        onChange={(ev) => {
                                          const revenue =
                                            ev.target.valueAsNumber;
                                          setFieldValue(
                                            `reductions[${index}].reductionPercentage`,
                                            !isNaN(revenue) ? revenue : ""
                                          );

                                          let scopeReduction =
                                            values.reductions.reduce(
                                              (prev, curr) =>
                                                prev +
                                                (curr.scope ==
                                                values.scopePreference
                                                  ? (parseFloat(
                                                      decarbEmissions?.find(
                                                        (item) =>
                                                          item?.activityType
                                                            ?.id ===
                                                          curr?.activityTypeId
                                                      )?.emissions
                                                    ) /
                                                      100) *
                                                    (parseFloat(
                                                      curr?.activityTypeId ===
                                                        r?.activityTypeId
                                                        ? revenue
                                                        : curr?.reductionPercentage
                                                    ) || 0)
                                                  : 0),
                                              0
                                            );
                                          let activityTypeEmissions =
                                            values.reductions.reduce(
                                              (prev, curr) =>
                                                prev +
                                                (curr.scope ==
                                                values.scopePreference
                                                  ? parseFloat(
                                                      decarbEmissions?.find(
                                                        (item) =>
                                                          item?.activityType
                                                            ?.id ===
                                                          curr.activityTypeId
                                                      )?.emissions
                                                    )
                                                  : 0),
                                              0
                                            );

                                          setFieldValue(
                                            `reductionPotentialScope${values.scopePreference}`,
                                            parseFloat(
                                              (scopeReduction /
                                                activityTypeEmissions) *
                                                100 || 0
                                            ).toFixed(2) || 0
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col xs={4} className="px-0">
                                      <Field
                                        type="number"
                                        name={`reductions[${index}].reductionPercentage`}
                                        as={Form.Control}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                  <Form.Text>
                                    {formatNumber(
                                      (
                                        (parseFloat(
                                          decarbEmissions?.find(
                                            (item) =>
                                              item?.activityType?.id ===
                                              r.activityTypeId
                                          )?.emissions
                                        ) /
                                          100) *
                                        (parseFloat(
                                          values?.reductions[index]
                                            ?.reductionPercentage
                                        ) || 0)
                                      ).toFixed(),
                                      0
                                    )}{" "}
                                    tCO<sub>2</sub>e
                                  </Form.Text>
                                </Form.Group>
                              </Row>
                            );
                          })}
                        </>
                      )}
                    />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner animation="border" size="sm" className="me-2" />
                  )}
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ManageReductionPotential;
