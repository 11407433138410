import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="Footer bg-dark text-light text-opacity-95 mt-auto">
      <Container fluid>
        <Row>
          <Col xs={12} className="my-3 text-center">
            <small>Copyright © {new Date().getFullYear()} ERM</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
