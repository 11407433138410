import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Modal,
  Button,
  Table,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { groupBy } from "lodash";
import CurrencyConverter from "components/Converter/CurrencyConverter/CurrencyConverter";
import NumberFormat from "react-number-format";

function PortfilioNzifEdit({
  organization,
  emissionYear,
  portfolioId,
  onOrganizationUpdated,
}) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [nzifGroup, setNzifGroup] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    aum: yup
      .number()
      .min(0, "Attribution factor cannot be less than 0!")
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      )
      .required("AUM is a required field"),
    rawUnit: yup.string(),
  });

  const initialValues = {
    aum: organization.aum || "",
    rawUnit: organization.rawUnit || "",
    rawAmount: organization.rawAmount || "",
  };

  useEffect(() => {
    const filteredNzifData = organization?.nzifDetails?.filter(
      (data) => data?.yearEnded === emissionYear + "-12-31"
    );
    const nzifData = groupBy(filteredNzifData, "yearEnded");
    setNzifGroup(nzifData);
  }, [organization]);

  const updatePortfolioOrganization = ({ aum, rawAmount, rawUnit }) => {
    setError(null);
    setIsLoading(true);
    const nzifPortfolioPromise = put(
      `/portfolios/${portfolioId}/organizations/${organization.id}`,
      {
        equityOwnership: organization?.equityOwnership,
        pivotId: organization?.pivotId,
        aum: aum,
        rawAmount: rawAmount,
        rawUnit: rawUnit,
      }
    );
    nzifPortfolioPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onOrganizationUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {Object?.keys(nzifGroup)?.length ? (
        <>
          <Button
            variant="outline-info"
            className="right py-0 me-1"
            size="sm"
            onClick={handleShow}
          >
            <small>AUM</small>
          </Button>

          <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                Add AUM for{" "}
                <b>
                  <i>{organization?.name}</i>
                </b>{" "}
                for{" "}
                <b>
                  <i>{emissionYear}</i>
                </b>
              </Modal.Title>
            </Modal.Header>
            <Formik
              validationSchema={schema}
              onSubmit={(values) => updatePortfolioOrganization(values)}
              initialValues={initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                isValid,
                errors,
                touched,
              }) => (
                <Form>
                  <Modal.Body>
                    {!!organization?.nzifDetails?.length && (
                      <Alert variant="dark" className="bg-light">
                        <h4>NZIF Details</h4>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>Reporting Year</th>
                              <th>Asset Type</th>
                              <th>Asset Breakdown</th>
                              <th>% of AUM</th>
                              <th>NZIF Category</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(nzifGroup).map(([key, value]) => {
                              return (
                                <tr key={key}>
                                  <td>{key?.slice(0, 4)}</td>
                                  <td>
                                    {value?.[0]?.assetType === 1
                                      ? "Infrastructure"
                                      : "Non-Infrastructure"}
                                  </td>
                                  <td>
                                    {value?.length > 1 ? (
                                      <>
                                        <span>Asset in Operation</span>
                                        <hr className="m-0" />
                                        <span>Asset Under Construction</span>
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td>
                                    {value?.length > 1 ? (
                                      <>
                                        {value?.sort(
                                          (a, b) =>
                                            a?.assetStatus - b?.assetStatus
                                        ) && (
                                          <>
                                            <span>
                                              {value?.[0]?.assetPercentage}%
                                            </span>
                                            <hr className="m-0" />
                                            <span>
                                              {value?.[1]?.assetPercentage}%
                                            </span>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <span>
                                        {value?.[0]?.assetPercentage}%
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {value?.length > 1 ? (
                                      <>
                                        {value?.sort(
                                          (a, b) =>
                                            a?.assetStatus - b?.assetStatus
                                        ) && (
                                          <>
                                            <span>
                                              {value?.[0]?.nzifCategory?.name}
                                            </span>
                                            <hr className="m-0" />
                                            <span>
                                              {value?.[1]?.nzifCategory?.name}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <span>
                                        {value?.[0]?.nzifCategory?.name}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Alert>
                    )}
                    <Form.Group controlId="equityOwnership" className="my-3">
                      <Form.Label>Update AUM (USD).</Form.Label>
                      <NumberFormat
                        customInput={Form.Control}
                        value={values.aum}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("aum", numberItem.value);
                        }}
                        name="aum"
                        placeholder="Enter AUM"
                        onBlur={handleBlur}
                        isValid={values?.aum}
                        isInvalid={errors.aum && touched.aum}
                        disabled={!Object.keys(nzifGroup)?.length}
                        className="md-6"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.aum}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <CurrencyConverter
                      setFieldValue={setFieldValue}
                      reportingYear={emissionYear?.toString()}
                      values={values}
                      emissionUnit={"USD"}
                      selectedOption={{
                        label: values?.rawUnit,
                        value: values?.rawUnit,
                      }}
                    />
                    {error && <ErrorHandler error={error} />}
                    {isLoading && <Loader />}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button size="sm" variant="link" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      size="sm"
                      disabled={!values?.aum || !isValid}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>NZIF details do not exist!</Tooltip>}
        >
          <span>
            <Button
              variant="outline-dark"
              className="right py-0 me-1"
              size="sm"
              disabled
            >
              <small>AUM</small>
            </Button>
          </span>
        </OverlayTrigger>
      )}
    </>
  );
}

PortfilioNzifEdit.propTypes = {
  organization: PropTypes.object.isRequired,
  portfolioId: PropTypes.string.isRequired,
  onOrganizationUpdated: PropTypes.func.isRequired,
};

export default PortfilioNzifEdit;
