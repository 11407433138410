import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { put, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { AccountContext } from "contexts/AccountProvider";
import SelectInputFormik from "components/SelectInput/SelectInput";
import { getYearMonths, getMonthLabelFromValue } from "utils/dateUtils";

const MONTHS = getYearMonths();

const OrganizationUpdate = ({ organization, onOrganizationUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const subscribedPromises = useRef([]);

  const [locations, setLocations] = useState([]);

  const account = useContext(AccountContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    description: yup.string().min(2, "Too Short!").max(256, "Too Long!"),
    location: yup
      .string()
      .required("Primary location of organization is required")
      .nullable(),
    industrySector: yup
      .string()
      .min(2, "Too short")
      .max(256, "Too long")
      .nullable(),
  });

  const fetchCountries = useCallback(() => {
    if (show) {
      const countryPromise = get("/countries");

      setError();
      setIsLoading(true);
      countryPromise.promise
        .then((response) => {
          setLocations(response.data);
          setError();
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
          console.error(error);
        });

      subscribedPromises.current.push(countryPromise);
    }
  }, [show]);

  const updateOrganization = ({
    name,
    description,
    location,
    industrySector,
    fiscalYearStart,
    fiscalYearEnd,
  }) => {
    setError(null);
    setIsLoading(true);

    const organizationPromise = put(`/organizations/${organization.id}`, {
      name: name,
      description: description,
      primaryLocation: location,
      industrySector,
      accountId: account.id,
      startOfYear: fiscalYearStart,
      endOfYear: fiscalYearEnd,
    });

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onOrganizationUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchCountries();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchCountries]);

  const handleValidateFiscalYearSelection = (values) => {
    if (!values.fiscalYearStart && !values.fiscalYearEnd) {
      return "";
    }

    const startMonth =
      MONTHS.find((month) => month.value === values.fiscalYearStart)?.value ||
      0;
    const endMonth =
      MONTHS.find((month) => month.value === values.fiscalYearEnd)?.value || 0;

    const difference = (endMonth - startMonth + 12) % 12;

    if (difference !== 11) {
      return "The fiscal year should cover a 12-month period.";
    }
    return "";
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        size="sm"
        className="py-0"
      >
        Update
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Organization <i>{organization.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateOrganization(values)}
          validateOnMount
          validateOnChange
          validateOnBlur
          initialValues={{
            name: organization.name,
            description: organization.description,
            location: organization.primaryLocation,
            industrySector: organization.industrySector || "",
            fiscalYearStart: organization.startOfYear,
            fiscalYearEnd: organization.endOfYear,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="organizationName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter organization's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="organizationLocation" className="my-3">
                  <Form.Label>Primary Location</Form.Label>
                  <Field
                    name="location"
                    value={values.location}
                    isValid={values.location}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name={field.name}
                        values={values}
                        options={locations.map((loc) => ({
                          label: loc.name,
                          value: loc.id,
                        }))}
                        isLoading={isLoading}
                        placeholder="Select organization's primary location"
                        form={form}
                        field={field}
                      />
                    )}
                  </Field>
                  <Form.Text className="text-danger">
                    {errors.location}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="industrySector" className="my-3">
                  <Form.Label>Primary Industry Sector</Form.Label>
                  <Form.Control
                    type="text"
                    name="industrySector"
                    value={values.industrySector}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter organization's primary industry sector"
                    isValid={values.industrySector}
                    isInvalid={errors.industrySector && touched.industrySector}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.industrySector}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="fiscalYearStart" className="my-3">
                  <Form.Label>Fiscal Year Start</Form.Label>
                  <Field
                    name="fiscalYearStart"
                    value={values.fiscalYearStart}
                    isValid={values.fiscalYearStart}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={(_) => handleValidateFiscalYearSelection(values)}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name={field.name}
                        values={values}
                        options={MONTHS}
                        isLoading={isLoading}
                        placeholder="Select organization's fiscal year start"
                        form={form}
                        field={field}
                        autoSort={false}
                        onChange={(option) => {
                          if (option) {
                            setFieldValue(field.name, option.value);
                            // set fiscal year end to 11 months later
                            const elevenMonthsLater = option.value + 11;
                            setFieldValue(
                              "fiscalYearEnd",
                              elevenMonthsLater > 12
                                ? elevenMonthsLater % 12
                                : elevenMonthsLater
                            );
                          }
                        }}
                      />
                    )}
                  </Field>
                  <Form.Text type="invalid" className="text-danger">
                    {touched.fiscalYearStart && errors.fiscalYearStart}
                  </Form.Text>
                  <Form.Text type="invalid" className="text-danger">
                    {Number.parseInt(values.fiscalYearStart) !==
                      account.startOfYear &&
                      `The fiscal year cycle is different from the one set for the account: ${getMonthLabelFromValue(
                        account.startOfYear
                      )} to ${getMonthLabelFromValue(account.endOfYear)}`}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="fiscalYearEnd" className="my-3">
                  <Form.Label>Fiscal Year End</Form.Label>
                  <Field
                    name="fiscalYearEnd"
                    value={values.fiscalYearEnd}
                    isValid={values.fiscalYearEnd}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={(_) => handleValidateFiscalYearSelection(values)}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name={field.name}
                        values={values}
                        options={MONTHS}
                        isLoading={isLoading}
                        placeholder="Select organization's fiscal year end"
                        form={form}
                        field={field}
                        autoSort={false}
                        isDisabled
                      />
                    )}
                  </Field>
                  <Form.Text type="invalid" className="text-danger">
                    {touched.fiscalYearEnd && errors.fiscalYearEnd}
                  </Form.Text>
                </Form.Group>

                <Form.Group
                  controlId="organizationDescription"
                  className="my-3"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    placeholder="Describe organization"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Update Organization
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

OrganizationUpdate.propTypes = {
  organization: PropTypes.object.isRequired,
  onOrganizationUpdated: PropTypes.func.isRequired,
};

export default OrganizationUpdate;
