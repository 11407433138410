import { groupBy } from "lodash";

export function addUnitsToDuplicateEmissionFactors(emissionFactors) {
  /* 
    This function aims to reformat emissionFactors with same name but different units.
    As such, they will not appear as duplicates on the ui
  */

  const result = [];
  const positions = {};

  // collect all positions
  emissionFactors.forEach((value, pos) => {
    positions[value.emissionResource] = positions[value.emissionResource] || [];
    positions[value.emissionResource].push(pos);
  });

  //check how much of same value in string
  Object.keys(positions).forEach((value) => {
    const posArray = positions[value];
    if (posArray.length > 1) {
      posArray.forEach((idx) => {
        result.push({
          label: `${emissionFactors[idx].emissionResource}-(${emissionFactors[idx].unit})`,
          value: emissionFactors[idx].id,
        });
      });
    } else {
      result.push({
        label: `${emissionFactors[posArray[0]].emissionResource}`,
        value: emissionFactors[posArray[0]].id,
      });
    }
  });

  return result.sort((a, b) => (a.label < b.label ? -1 : 1));
}

export function removeUnitsToDuplicateEmissionFactors(emissionFactors) {
  /* 
    This function aims to reformat emissionFactors with same name.
    As such, they will not appear as duplicates on the ui
  */

  const result = [];
  const positions = {};

  // collect all positions
  emissionFactors.forEach((value, pos) => {
    positions[value.emissionResource] = positions[value.emissionResource] || [];
    positions[value.emissionResource].push(pos);
  });

  //check how much of same value in string
  Object.keys(positions).forEach((value) => {
    const posArray = positions[value];
    if (posArray.length > 1) {
      posArray.forEach((idx) => {
        result.push({
          label: `${emissionFactors[idx].emissionResource}`,
          value: emissionFactors[idx].id,
        });
      });
    } else {
      result.push({
        label: `${emissionFactors[posArray[0]].emissionResource}`,
        value: emissionFactors[posArray[0]].id,
      });
    }
  });

  return result.sort((a, b) => (a.label < b.label ? -1 : 1));
}

export function removeDuplicateEmissionFactorsOnReference(emissionFactors) {
  // remove duplicate emission factors
  const result = [];
  const positions = {};

  // collect all positions
  emissionFactors.forEach((value, pos) => {
    positions[value.reference] = positions[value.reference] || [];
    positions[value.reference].push(pos);
  });

  //check how much of same value in string
  Object.keys(positions).forEach((value) => {
    const posArray = positions[value];
    result.push(emissionFactors[posArray[0]]);
  });

  return result.sort((a, b) => (a.reference < b.reference ? -1 : 1));
}

export function removeDuplicateOptions(
  options,
  key = "value",
  ifDuplicatesRetainWithValue = "",
  sortAlphabetically = true
) {
  if (key === "label") {
    return removeDuplicateOptionsOnLabel(
      options,
      ifDuplicatesRetainWithValue,
      sortAlphabetically
    );
  }
  // remove duplicate options on value
  const result = [];
  const positions = {};

  // collect all positions
  options.forEach((option, pos) => {
    positions[option.value] = positions[option.value] || [];
    positions[option.value].push(pos);
  });

  //check how much of same option in string
  Object.keys(positions).forEach((option) => {
    const posArray = positions[option];
    result.push(options[posArray[0]]);
  });

  if (!sortAlphabetically) return result;

  return result.sort((a, b) => (a.value < b.value ? -1 : 1));
}

export function removeDuplicateOptionsOnLabel(
  options,
  ifDuplicatesRetainWithValue,
  sortAlphabetically
) {
  // remove duplicate options on label
  const result = [];
  const positions = {};

  // collect all positions
  options.forEach((option, pos) => {
    positions[option.label] = positions[option.label] || [];
    positions[option.label].push(pos);
  });

  if (!!ifDuplicatesRetainWithValue) {
    // if duplicates, retain the one with value of ifDuplicatesRetainWithValue
    Object.keys(positions).forEach((option) => {
      const posArray = positions[option];
      const optionsInPositions = options.filter((opt, index) =>
        posArray.includes(index)
      );
      result.push(
        optionsInPositions.find(
          (o) => o.value === ifDuplicatesRetainWithValue
        ) || options[posArray[0]]
      );
    });
  } else {
    // of duplicates retain the first one
    Object.keys(positions).forEach((option) => {
      const posArray = positions[option];
      result.push(options[posArray[0]]);
    });
  }

  if (!sortAlphabetically) return result;

  return result.sort((a, b) => (a.label < b.label ? -1 : 1));
}

export function filterEmissionFactorsOnMetadataValue(
  _emissionFactors,
  metavalue
) {
  if (!metavalue) {
    // metavalue is empty string, so return all emission factors
    return _emissionFactors;
  }
  const emFactors = _emissionFactors.filter((factor) => {
    const isMatchingMeta = factor.metadata?.find(
      (ef) => ef.value === metavalue
    );

    return isMatchingMeta;
  });
  return emFactors;
}

export const getEmissionFactorFromId = (_emissionFactors, emissionFactorId) => {
  return _emissionFactors.find((factor) => factor.id === emissionFactorId);
};

export const isInflationFactorAppliedOnActivity = (activityTypeDataset) => {
  return activityTypeDataset?.inflationFactors?.length > 0;
};

export const isCapexFinancial = (activityTypeId) => {
  // 630f3d696f78b is the id of the Capexfinancial ActivityType on sandbox environment
  // 631f2007542cd is the id of the Capexfinancial ActivityType on production environment
  return (
    activityTypeId === "6262893dd3d00" || activityTypeId === "628ee7ceb94f0"
  );
};

export const getEmissionFactors = (
  emissionFactors,
  metadatakeys = [],
  values = {}
) => {
  let emFactors = emissionFactors;
  metadatakeys.forEach((key) => {
    emFactors = filterEmissionFactorsOnMetadataValue(emFactors, values[key]);
  });
  // add units to duplicate emission factors
  return addUnitsToDuplicateEmissionFactors(emFactors);
};

export const isInvestments = (emFactorType) => {
  return emFactorType && emFactorType.toLowerCase().includes("investments");
};

export const isUSEEIO2018Dataset = (referenceId) => {
  // 636922a064b07 is the id of the USEEIO 2018 dataset on sandbox environment
  // 632c13bfd1329 is the id of the USEEIO 2018 dataset on production environment
  return referenceId === "632c13bfd1329" || referenceId === "636922a064b07";
};

export const isExiobase2011Dataset = (referenceId) => {
  // 630f3d696f78b is the id of the Exiobase 2011 dataset on sandbox environment
  // 631f2007542cd is the id of the Exiobase 2011 dataset on production environment
  return referenceId === "631f2007542cd" || referenceId === "630f3d696f78b";
};

export const getInflationRateFromYear = (year, dataset = {}) => {
  const inflationFactorRates = dataset?.inflationFactors;
  const inflationFactorRate = inflationFactorRates?.find(
    (rate) => String(rate.year) === String(year)
  );
  return inflationFactorRate?.value || "N/A";
};

export const validateDatasetReferenceField = (year, dataset) => {
  const isDeleted = dataset?.deleted;
  if (isDeleted === true) {
    return "This dataset has been deleted. Please select with the available dataset list.";
  }

  const inflationFactorRates = dataset?.inflationFactors;

  if (inflationFactorRates?.length === 0) {
    return;
  }

  // validate if the year is in the range of the dataset
  const minYear = inflationFactorRates?.reduce(
    (min, p) => (p.year < min ? p.year : min),
    inflationFactorRates[0].year
  );
  const maxYear = inflationFactorRates?.reduce(
    (max, p) => (p.year > max ? p.year : max),
    inflationFactorRates[0].year
  );

  if (!(year >= minYear && year <= maxYear)) {
    return `Please select year ${minYear} and after to apply the ${dataset.name} factors.`;
  }
};

export const getMetadataFromEmissionFactors = (emissionFactors) => {
  const metadata = emissionFactors
    .map((factor) => {
      return factor.metadata?.map((meta) => ({
        ...meta,
        label: meta.value,
      }));
    })
    .filter((meta) => meta && meta.length > 0)
    .flat();
  return groupBy(metadata, "type");
};

export const getMetaDataFieldOptions = (
  emissionFactors,
  metaType,
  filterByType = "",
  filterByValue = ""
) => {
  let emFactors;
  if (filterByType) {
    emFactors = emissionFactors.filter(
      (factor) =>
        !!factor.metadata?.find(
          (meta) => meta.type === filterByType && meta.value === filterByValue
        )
    );
  }
  emFactors = emFactors.filter(
    (factor) => !!factor.metadata?.find((meta) => meta.type === metaType)
  );
  const metadata = emFactors
    .map((factor) => {
      const meta = factor.metadata?.find((meta) => meta.type === metaType);
      return {
        label: meta?.value,
        value: meta?.value,
      };
    })
    .filter((meta) => !!meta.value && !!meta.label);
  return removeDuplicateOptions(metadata, "label");
};

export function getEmissionFactorOptions(emissionFactors = []) {
  return emissionFactors.map((emFactor) => ({
    label: emFactor.emissionResource,
    value: emFactor.id,
    subLabel:
      emFactor.unit +
      emFactor.metadata
        ?.map((meta) => (meta?.value ? `, ${meta?.value}` : ""))
        .join(""),
  }));
}
