import React from "react";
import ReactHtmlParser, { processNodes } from "react-html-parser";
import { Text, View, Link } from "@react-pdf/renderer";

const ExportContent = ({ content, styles }) => {
  const options = { decodeEntities: true, buffer: false, transform };

  function transform(node, index) {
    if (
      node.type === "tag" &&
      ["p", "h1", "h2", "h3", "h4", "h5", "h6", "li"].includes(node.name)
    ) {
      const children = processNodes(node.children, transform);
      return (
        <Text key={index} style={styles.contentText}>
          {children}
        </Text>
      );
    }

    if (node.type === "tag" && node.name === "a") {
      const children = processNodes(node.children, transform);
      return (
        <Link src={node.attribs.href} style={styles.contentLink} key={index}>
          {children}
        </Link>
      );
    }

    if (node.type === "tag" && node.name === "strong") {
      const children = processNodes(node.children, transform);
      return children;
    }

    if (node.type === "tag") {
      const children = processNodes(node.children, transform);
      return <Text key={index}>{children}</Text>;
    }
  }

  var htmlBody = ReactHtmlParser(content.contentBody, options);

  return (
    <View style={styles.contentSection}>
      <View>{htmlBody}</View>
    </View>
  );
};

export default ExportContent;
