import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import { Alert, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

import { get } from "utils/DeApi";

import { useSearchParams } from "react-router-dom";
import { AccountContext } from "contexts/AccountProvider";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const PortfolioToggler = () => {
  const subscribedPromises = useRef([]);

  const account = useContext(AccountContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [portfolio, setPortfolio] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    setError(null);
    setIsLoading(true);

    let fetchPortfolios = get(`members/${account.member.id}/portfolios`, {
      params: { accountId: account.id },
    });

    fetchPortfolios.promise
      .then((response) => {
        setPortfolios(response.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fetchPortfolios);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [account.id, account.member.id]);

  useEffect(() => {
    setPortfolio(
      portfolios.find((p) => p.id === searchParams.get("portfolioId"))
    );
  }, [searchParams, portfolios]);

  const handlePortfolioChange = (p) => {
    setPortfolio(p);
    if (p) setSearchParams({ portfolioId: p.id });
    else {
      delete searchParams.portfolioId;
      setSearchParams({ ...searchParams });
    }
  };

  return (
    <DropdownButton
      as={ButtonGroup}
      title={portfolio?.name || "Select Portfolio"}
      size="sm"
      variant="outline-primary"
      className="mr-2 ml-2"
    >
      <Dropdown.Item
        active={!portfolio}
        onClick={(e) => handlePortfolioChange()}
      >
        All Organizations
      </Dropdown.Item>
      {(() => {
        return (
          <>
            {isLoading && <Loader />}
            {error && <ErrorHandler error={error} />}
            {!portfolios.length && (
              <Dropdown.Item>
                <Alert variant="info" className="mb-0">
                  There are currently no portfolios to show.
                </Alert>
              </Dropdown.Item>
            )}
            {portfolios.map((pf) => (
              <Dropdown.Item
                key={pf.id}
                eventKey={pf.id}
                active={pf.id === portfolio?.id}
                onClick={(e) => handlePortfolioChange(pf)}
              >
                {pf.name}
              </Dropdown.Item>
            ))}
          </>
        );
      })()}
    </DropdownButton>
  );
};

export default PortfolioToggler;
