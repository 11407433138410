import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { post, download } from "utils/DeApi";

function LockUnlockComment({
  year,
  site,
  organization,
  siteYearsUnderReview,
  setOnSiteUpdate,
}) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [exportingError, setExportingError] = useState();
  const [isExported, setIsExported] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsExporting(false);
    setExportingError();
    setIsExported(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    comment: yup.string().required("Comment is a required field!"),
  });

  const initialValues = {
    comment: "",
  };

  const checkLockUnlock = siteYearsUnderReview?.includes(Number(year));

  const updateSiteReviewWorkflow = ({ comment }) => {
    const updateSiteReviewWorkflow = post(`/audit-activities`, {
      siteId: site.id,
      year: `${year}-12-31`,
      stage: 1,
      status: checkLockUnlock ? 1 : 2,
      comment: comment,
    });

    setIsLoading(true);
    setError("");
    updateSiteReviewWorkflow.promise
      .then((response) => {
        setOnSiteUpdate(true);
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });
  };

  const exportActivities = () => {
    setIsExporting(true);

    const exportPromise = download(
      `/organizations/${organization?.id}/activities/export`,
      {
        params: {
          "filter[sites]": site?.id,
          "filter[yearEnded]": `${year}-12-31`,
        },
      }
    );

    exportPromise.promise
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `${site?.name} - Activities for ${year}.xlsx`
        );
        document.body.appendChild(link);

        link.click();
        setIsExporting(false);
        setIsExported(true);
      })
      .catch((error) => {
        setIsExporting(false);
        setIsExported(false);
        setExportingError(error);
        console.warn(error);
      });
  };

  return (
    <>
      <Button
        size="sm"
        variant={checkLockUnlock ? "info" : "outline-info"}
        className={`py-0 ${checkLockUnlock && "text-white"}`}
        onClick={() => handleShow()}
      >
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>
              {!checkLockUnlock ? "Lock this year" : "Unlock this year"}
            </Tooltip>
          }
        >
          <span className="material-icons-outlined md-20">
            {checkLockUnlock ? "lock" : "lock_open"}
          </span>
        </OverlayTrigger>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Please provide comment to{" "}
            <span className="text-decoration-underline">
              {checkLockUnlock ? "unlock" : "lock"}
            </span>{" "}
            the GHG inventory data for reporting year{" "}
            <b>
              <i>{year}</i>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values) => updateSiteReviewWorkflow(values)}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="organizationName" className="my-2">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.comment}
                    isInvalid={errors.comment && touched.comment}
                    className="md-6"
                    as="textarea"
                    rows={2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.comment}
                  </Form.Control.Feedback>
                </Form.Group>
                {checkLockUnlock && (
                  <Form.Text className="text-danger">
                    To activate the unlock function, please enter comment and
                    then export the inventory data for your record.
                  </Form.Text>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer
                className={checkLockUnlock && "justify-content-between"}
              >
                {checkLockUnlock && (
                  <Button
                    className="px-3"
                    size="sm"
                    variant="primary"
                    onClick={exportActivities}
                    disabled={!values?.comment || isExporting}
                  >
                    Export Activities{"  "}
                    {isExporting && (
                      <Spinner
                        animation="border"
                        variant="white"
                        size="sm"
                        className="ms-3"
                      />
                    )}
                    {exportingError && (
                      <span className="material-icons-outlined md-18 text-danger">
                        error
                      </span>
                    )}
                  </Button>
                )}
                <span>
                  <Button size="sm" variant="link" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    disabled={
                      !values?.comment ||
                      !isValid ||
                      (checkLockUnlock && !isExported)
                    }
                  >
                    {checkLockUnlock ? "Unlock" : "Lock"}
                  </Button>
                </span>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default LockUnlockComment;
