import React, { useState } from "react";
import "./Map.scss";

import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";
import { Alert, Col, Form, Row } from "react-bootstrap";

const SiteMap = ({ sites, sitesSummary }) => {
  const [year, setYear] = useState("2021");

  const currentYear = new Date().getFullYear();

  const center = { lat: 0, lng: 0 };

  var sitesLocation = sites.map((item) => ({
    lat: item.latitude,
    lng: item.longitude,
  }));

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, sitesLocation) => {
    const bounds = new maps.LatLngBounds();

    sitesLocation.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      window.addEventListener("resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, sitesLocation) => {
    maps.event.addListener(map, "zoom_changed", () => {
      let initialZoom = map?.initialZoom;
      let zoomChangeBoundsListener = maps.event.addListener(
        map,
        "bounds_changed",
        function (event) {
          if (map?.getZoom() > 4 && initialZoom === true) {
            // Change max/min zoom here
            map?.setZoom(4);
            initialZoom = false;
          }
          maps.event.removeListener(zoomChangeBoundsListener);
        }
      );
    });
    map.initialZoom = true;

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, sitesLocation);
    // Fit map to bounds

    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };
  const maxEmissions = sitesSummary.reduce(
    (currMax, item) => (item.emissions > currMax ? item.emissions : currMax),
    0
  );
  const minEmissions = sitesSummary.reduce(
    (currMin, item) => (item.emissions < currMin ? item.emissions : currMin),
    sitesSummary[0]?.emissions || 0
  );

  return (
    <>
      <div className="w-100" style={{ height: "400px" }}>
        <Alert
          variant="light"
          className="map-year-filter text-center pt-0 pb-0 border border-1"
        >
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              {year}
            </Form.Label>
            <Col sm={9}>
              <Form.Range
                className="pt-3"
                min="2010"
                max={currentYear}
                value={year}
                step="1"
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setYear(selectedYear);
                }}
              />
            </Col>
          </Form.Group>
        </Alert>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: "places",
          }}
          center={center}
          defaultZoom={4}
          options={{
            minZoom: 2,
            maxZoom: 12,
            styles: [
              {
                elementType: "geometry",
                stylers: [
                  {
                    color: "#f5f5f5",
                  },
                ],
              },
              {
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    color: "#f5f5f5",
                  },
                ],
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#bdbdbd",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#eeeeee",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#e5e5e5",
                  },
                ],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#ffffff",
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#757575",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#dadada",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#616161",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
              {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#e5e5e5",
                  },
                ],
              },
              {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#eeeeee",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#c9c9c9",
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#9e9e9e",
                  },
                ],
              },
            ],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            apiIsLoaded(map, maps, sitesLocation)
          }
        >
          {sites?.map((site, i) => (
            <LocationPin
              key={i}
              lat={site?.latitude || 0}
              lng={site?.longitude || 0}
              site={site}
              siteSummary={sitesSummary.filter(
                (item) => item.siteId === site.id && item.yearEnded == year
              )}
              maxEmissions={maxEmissions}
              minEmissions={minEmissions}
            />
          ))}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default SiteMap;
