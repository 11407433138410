import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { Badge, NavDropdown } from "react-bootstrap";

import { get, put } from "utils/DeApi";

import {
  AccountContext,
  AccountDispatchContext,
} from "contexts/AccountProvider";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import "./AccountToggler.scss";

const AccountToggler = () => {
  const subscribedPromises = useRef([]);

  const [accounts, setAccounts] = useState([]);
  const [invitations, setInvitations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const account = useContext(AccountContext);
  const setAccount = useContext(AccountDispatchContext);

  const fetchAccounts = () => {
    setError(null);
    setIsLoading(true);
    let accountsPromise = get(`accounts`);
    let invitationsPromise = get(`invitations`);

    Promise.all([accountsPromise.promise, invitationsPromise.promise])
      .then((responses) => {
        setIsLoading(false);
        setError(null);
        setAccounts(responses[0].data);
        setInvitations(responses[1].data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(accountsPromise, invitationsPromise);
  };

  const joinAccount = (invitation) => {
    setError(null);
    setIsLoading(true);
    let joinAccount = put(`invitations/${invitation.token}`);
    joinAccount.promise
      .then(() => {
        setIsLoading(false);
        setError(null);
        fetchAccounts();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchAccounts();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);
  return (
    <div className="AccountToggler">
      <NavDropdown
        title={
          <span>
            {account.name}{" "}
            {!!invitations.length && (
              <sup>
                <Badge pill bg="danger">
                  {invitations.length}
                </Badge>
              </sup>
            )}
          </span>
        }
      >
        {isLoading && (
          <NavDropdown.Item>
            <Loader />
          </NavDropdown.Item>
        )}
        {error && (
          <NavDropdown.Item>
            <ErrorHandler error={error} />
          </NavDropdown.Item>
        )}
        <div className="account-scroll">
          {accounts.map((item) => (
            <NavDropdown.Item
              onClick={() => setAccount(item)}
              key={item.id}
              active={item.id === account.id}
            >
              {item.name}
            </NavDropdown.Item>
          ))}
        </div>

        {!!invitations.length && (
          <Fragment>
            <NavDropdown.Divider />
            <NavDropdown.ItemText>
              <small className="text-muted">New Accounts</small>
            </NavDropdown.ItemText>
            {invitations.map((item) => (
              <NavDropdown.Item
                onClick={() => {
                  joinAccount(item);
                }}
                key={item.account.id}
              >
                Join <i>{item.account.name}</i>
              </NavDropdown.Item>
            ))}
          </Fragment>
        )}
      </NavDropdown>
    </div>
  );
};

export default AccountToggler;
