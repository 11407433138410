import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SiteEmissionsByYear.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";
import { groupBy } from "lodash";

const SiteEmissionsByYear = ({ emissionsSummary }) => {
  const el = useRef(null);

  useEffect(() => {
    const emissionsYears = [];
    let endDate = new Date();
    let currentYear = endDate.getFullYear();
    while (emissionsYears.length <= 10) {
      const year = currentYear--;
      emissionsYears.push(year.toString());
    }
    emissionsYears.reverse();

    const summarySeries = emissionsSummary
      .map((item) => {
        return [
          {
            stack: "Scope 1",
            emissions: item.scopeOneEmissions,
            year: item.yearEnded.substring(0, 4),
          },
          {
            stack: "Scope 2",
            emissions: item.scopeTwoEmissions,
            year: item.yearEnded.substring(0, 4),
          },
          {
            stack: "Scope 3",
            emissions: item.scopeThreeEmissions,
            year: item.yearEnded.substring(0, 4),
          },
        ];
      })
      .flat();

    const series = summarySeries.map((item) => {
      return {
        name: item.stack,
        type: "bar",
        stack: "emissions",
        barMaxWidth: 60,

        emphasis: { focus: "series" },
        data: emissionsYears.map((year) => {
          let summary = item.year === year ? item : null;
          return {
            value: summary?.emissions,
            itemStyle: { opacity: 0.8 },
          };
        }),
      };
    });
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      color: ["#b5c334", "#fac858", "#eb8146"],

      legend: { type: "scroll" },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      xAxis: {
        type: "category",
        data: emissionsYears.map((item) => item.substring(0, 4)),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) =>
            Intl.NumberFormat("en-US", { notation: "compact" }).format(value) +
            " tCO₂e",
        },
      },
      series: series,
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, emissionsSummary]);

  return (
    <div className="SiteEmissionsByYear">
      <h4 className="mt-1 mb-3">Emissions By Scope</h4>
      <div className="site-emissions-year-chart-container me-auto" ref={el} />
    </div>
  );
};

SiteEmissionsByYear.propTypes = {
  emissionsSummary: PropTypes.array.isRequired,
};

export default SiteEmissionsByYear;
