import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Table, Alert } from "react-bootstrap";
import { get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { getFormattedEmission } from "utils/StringUtils";
import "./PortfolioOrganizationHistory.scss";

function PortfolioOrganizationHistory({
  organization,
  portfolio,
  portfolioId,
}) {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [orgHistory, setOrgHistory] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "reportingYear",
    sortAsc: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchOrganizationHistory = () => {
    setError(null);
    setIsLoading(true);

    const organizationHistoryPromise = get(
      `/portfolios/${portfolioId}/organizations/${organization.id}/history`
    );
    organizationHistoryPromise.promise
      .then((response) => {
        setOrgHistory(response.data);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationHistoryPromise);
  };

  useEffect(() => {
    const data = orgHistory?.map((org) => ({
      ...org,
      assetType: !!org?.nzifDetails?.length
        ? org?.nzifDetails?.[0]?.assetType
        : 3,
    }));
    data?.sort((a, b) => (a["reportingYear"] <= b["reportingYear"] ? 1 : -1));
    setTableData(data);
  }, [orgHistory]);

  const sortTable = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <>
      <Button
        variant="outline-darkOrange"
        className="right py-0 me-1"
        size="sm"
        onClick={() => {
          handleShow();
          fetchOrganizationHistory();
        }}
      >
        <span className="material-icons-outlined md-18">history</span>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            History for{" "}
            <b>
              <i>{organization?.name}</i>
            </b>{" "}
            in{" "}
            <b>
              <i>{portfolio?.name}</i>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isLoading ? (
              <Loader />
            ) : !!orgHistory?.length ? (
              <div>
                <Table
                  size="sm"
                  responsive
                  className="first-column-fixed table-head-fixed table-head-top table-scroller"
                >
                  <thead>
                    <tr>
                      <th
                        className="table-sorter table-header first-column-wd"
                        onClick={() => sortTable("reportingYear")}
                      >
                        Reporting <br /> Year
                        {tableSortState.sortColumn === "reportingYear" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter text-end"
                        onClick={() => sortTable("topDownEmissions")}
                      >
                        Top-Down
                        <br />
                        Estimation
                        <br />
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        {tableSortState.sortColumn === "topDownEmissions" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter text-end"
                        onClick={() => sortTable("ghgEmissions")}
                      >
                        Bottom-Up <br /> GHG Inventory
                        <br />
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        {tableSortState.sortColumn === "ghgEmissions" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter text-end"
                        onClick={() => sortTable("equityOwnership")}
                      >
                        Attribution <br />
                        Factor
                        {tableSortState.sortColumn === "equityOwnership" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter text-end"
                        onClick={() => sortTable("attributableEmissions")}
                      >
                        Attributable <br />
                        Emissions
                        <br />
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        {tableSortState.sortColumn ===
                          "attributableEmissions" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter text-end"
                        onClick={() => sortTable("aum")}
                      >
                        AUM (USD)
                        {tableSortState.sortColumn === "aum" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th
                        className="table-sorter"
                        onClick={() => sortTable("assetType")}
                      >
                        Asset Type
                        {tableSortState.sortColumn === "assetType" && (
                          <span className="material-icons-outlined mx-1 md-20 text-muted">
                            {!tableSortState.sortAsc && "arrow_downward"}
                            {tableSortState.sortAsc && "arrow_upward"}
                          </span>
                        )}
                      </th>
                      <th>Asset Breakdown</th>
                      <th className="text-end">% of AUM</th>
                      <th>NZIF Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((data) => (
                      <tr key={data?.yearEnded}>
                        <td className="first-column-wd align-middle">
                          {data?.reportingYear?.slice(0, 4)}
                        </td>
                        <td className="align-middle text-end">
                          {getFormattedEmission(data?.topDownEmissions || 0)}
                        </td>
                        <td className="align-middle text-end">
                          {getFormattedEmission(data?.ghgEmissions || 0)}
                        </td>
                        <td className="align-middle text-end">
                          {data?.equityOwnership}
                          <small>%</small>
                        </td>
                        <td className="align-middle text-end">
                          {getFormattedEmission(
                            data?.attributableEmissions || 0
                          )}
                        </td>
                        <td className="align-middle text-end">
                          {Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(data?.aum) || 0}
                        </td>
                        <td className="align-middle text-nowrap">
                          {!!data?.nzifDetails?.length ? (
                            data?.nzifDetails?.[0]?.assetType === 1 ? (
                              "Infrastructure"
                            ) : (
                              "Non-Infrastructure"
                            )
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                        <td className="align-middle text-nowrap">
                          {data?.nzifDetails?.length > 1 ? (
                            <>
                              <span>Asset in Operation</span>
                              <hr className="m-0" />
                              <span>Asset Under Construction</span>
                            </>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                        <td className="align-middle text-end">
                          {!!data?.nzifDetails?.length ? (
                            data?.nzifDetails?.length > 1 ? (
                              <>
                                {data?.nzifDetails?.sort(
                                  (a, b) => a?.assetStatus - b?.assetStatus
                                ) && (
                                  <>
                                    <span>
                                      {data?.nzifDetails?.[0]?.assetPercentage}%
                                    </span>
                                    <hr className="m-0" />
                                    <span>
                                      {data?.nzifDetails?.[1]?.assetPercentage}%
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <span>
                                {data?.nzifDetails?.[0]?.assetPercentage}%
                              </span>
                            )
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                        <td className="align-middle text-nowrap">
                          {!!data?.nzifDetails?.length ? (
                            data?.nzifDetails?.length > 1 ? (
                              <>
                                {data?.nzifDetails?.sort(
                                  (a, b) => a?.assetStatus - b?.assetStatus
                                ) && (
                                  <>
                                    <span>
                                      {
                                        data?.nzifDetails?.[0]?.nzifCategory
                                          ?.name
                                      }
                                    </span>
                                    <hr className="m-0" />
                                    <span>
                                      {
                                        data?.nzifDetails?.[1]?.nzifCategory
                                          ?.name
                                      }
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <span>
                                {data?.nzifDetails?.[0]?.nzifCategory?.name}
                              </span>
                            )
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <Alert variant="info">
                There is currently nothing to show here.
              </Alert>
            )}
          </div>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="link" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

PortfolioOrganizationHistory.propTypes = {
  organization: PropTypes.object.isRequired,
  portfolioId: PropTypes.string.isRequired,
};

export default PortfolioOrganizationHistory;
