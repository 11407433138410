import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { AccountContext } from "contexts/AccountProvider";

const PortfolioUpdate = ({ portfolio, onPortfolioUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const account = useContext(AccountContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    description: yup.string().min(2, "Too Short!").max(256, "Too Long!"),
  });

  const updatePortfolio = ({ name, description }) => {
    setError(null);
    setIsLoading(true);

    const portfolioPromise = put(`/portfolios/${portfolio.id}`, {
      name: name,
      description: description,
      accountId: account.id,
    });

    portfolioPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onPortfolioUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        size="sm"
        className="py-0"
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Portfolio <i>{portfolio.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updatePortfolio(values)}
          initialValues={{
            name: portfolio.name,
            description: portfolio.description || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="portfolioName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter portfolio's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="portfolioDescription" className="my-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    placeholder="Describe portfolio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Update Portfolio
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PortfolioUpdate.propTypes = {
  portfolio: PropTypes.object.isRequired,
  onPortfolioUpdated: PropTypes.func.isRequired,
};

export default PortfolioUpdate;
