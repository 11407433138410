import React, { useState, useCallback, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { put } from "utils/DeApi";
import {
  AccountDispatchContext,
  AccountContext,
} from "contexts/AccountProvider";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import SelectInputFormik from "components/SelectInput/SelectInput";

import { getYearMonths, getMonthLabelFromValue } from "utils/dateUtils";

const MONTHS = getYearMonths();

const UpdateAccountFiscalYear = () => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const account = useContext(AccountContext);
  const setAccount = useContext(AccountDispatchContext);

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    fiscalYearStart: yup
      .number()
      .required("Account fiscal year start month is required"),
    fiscalYearEnd: yup
      .number()
      .required("Account fiscal year start month is required"),
  });

  const updateAccountFiscalYear = useCallback(
    ({ fiscalYearStart, fiscalYearEnd }) => {
      setError(null);
      setIsLoading(true);

      const updateGwpPreference = put(
        `/accounts/${account.id}/update-year-settings`,
        {
          startOfYear: fiscalYearStart,
          endOfYear: fiscalYearEnd,
        }
      );

      updateGwpPreference.promise
        .then((response) => {
          setAccount({
            ...account,
            startOfYear: response.data.startOfYear,
            endOfYear: response.data.endOfYear,
          });
          handleClose();
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    },
    [account, setAccount]
  );

  const handleValidateFiscalYearSelection = (values) => {
    if (!values.fiscalYearStart && !values.fiscalYearEnd) {
      return "";
    }

    const startMonth =
      MONTHS.find((month) => month.value === values.fiscalYearStart)?.value ||
      0;
    const endMonth =
      MONTHS.find((month) => month.value === values.fiscalYearEnd)?.value || 0;

    const difference = (endMonth - startMonth + 12) % 12;

    if (difference !== 11) {
      return "The fiscal year should cover a 12-month period.";
    }
    return "";
  };

  return (
    <>
      <span>
        <span className="fw-bold">Fiscal Year:</span>{" "}
        {getMonthLabelFromValue(account?.startOfYear)} to{" "}
        {getMonthLabelFromValue(account?.endOfYear)}
      </span>
      <Button variant="primary" className="mx-3" size="sm" onClick={handleShow}>
        Update Fiscal Year
      </Button>
      <Modal show={show} backdrop={"static"} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Fiscal year for <i>{account.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateAccountFiscalYear(values)}
          initialValues={{
            fiscalYearStart: account.startOfYear,
            fiscalYearEnd: account.endOfYear,
          }}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="fiscalYearStart" className="my-3">
                  <Form.Label>Fiscal Year Start</Form.Label>
                  <Field
                    name="fiscalYearStart"
                    value={values.fiscalYearStart}
                    isValid={values.fiscalYearStart}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={(_) => handleValidateFiscalYearSelection(values)}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name={field.name}
                        values={values}
                        options={MONTHS}
                        isLoading={isLoading}
                        placeholder="Select organization's fiscal year start"
                        form={form}
                        field={field}
                        autoSort={false}
                        onChange={(option) => {
                          if (option) {
                            setFieldValue(field.name, option.value);
                            // set fiscal year end to 11 months later
                            const elevenMonthsLater = option.value + 11;
                            setFieldValue(
                              "fiscalYearEnd",
                              elevenMonthsLater > 12
                                ? elevenMonthsLater % 12
                                : elevenMonthsLater
                            );
                          }
                        }}
                      />
                    )}
                  </Field>
                  <Form.Text type="invalid" className="text-danger">
                    {touched.fiscalYearStart && errors.fiscalYearStart}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="fiscalYearEnd" className="my-3">
                  <Form.Label>Fiscal Year End</Form.Label>
                  <Field
                    name="fiscalYearEnd"
                    value={values.fiscalYearEnd}
                    isValid={values.fiscalYearEnd}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={(_) => handleValidateFiscalYearSelection(values)}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name={field.name}
                        values={values}
                        options={MONTHS}
                        isLoading={isLoading}
                        placeholder="Select organization's fiscal year end"
                        form={form}
                        field={field}
                        autoSort={false}
                        isDisabled
                      />
                    )}
                  </Field>
                  <Form.Text type="invalid" className="text-danger">
                    {touched.fiscalYearEnd && errors.fiscalYearEnd}
                  </Form.Text>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Update Account
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateAccountFiscalYear;
