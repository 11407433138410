import BottomUpCategories from "components/Organization/DataModels/BottomUpCategories";
import { useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

const useContents = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();
  const activityTypeId = searchParams.get("activityCategoryId");

  useEffect(() => {
    const activityTypeId = searchParams.get("activityCategoryId");
    const emissionFactorTypeId = searchParams.get("emissionFactorTypeId");

    if (emissionFactorTypeId && !activityTypeId) {
      // user is on old url params, redirect to site details page
      navigate(
        `/organizations/${params.organizationId}/sites/${params.siteId}`
      );
    }
  }, [searchParams, params, navigate]);

  return (
    BottomUpCategories.find((item) => item.activityTypeId === activityTypeId)
      ?.contentArticleId || ""
  );
};

export default useContents;
