import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DecarbonizationChart.scss";
import * as echarts from "echarts";
import {
  convertNegativeToZero,
  findLastNotEqual,
  formatNumber,
} from "../helper";

const DecarbonizationChart = ({ data = [], scopeOne = 0 }) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);
  useEffect(() => {
    const _data = data.reduce(
      (prev, { year, emission, s1_emission, s3_emission }) => ({
        year: [...prev.year, year],
        emission: [...prev.emission, convertNegativeToZero(emission)],
        s1_emission: [...prev.s1_emission, convertNegativeToZero(s1_emission)],
        s3_emission: [...prev.s3_emission, convertNegativeToZero(s3_emission)],
      }),
      {
        year: [],
        emission: [],
        s1_emission: [],
        s3_emission: [],
      }
    );

    const { value, index } = findLastNotEqual(_data.emission, scopeOne);

    if (value && index >= 0) {
      _data.emission = _data.emission.map((item, ii) => {
        return ii > index ? value : item;
      });
    }

    const option = {
      title: {
        show: data.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: "10%",
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 2),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        {
          data: _data.emission,
          type: "line",
          name: "Scopes 1+3",
        },
        {
          data: _data.s1_emission,
          type: "line",
          name: "Scope 1",
        },
        {
          data: _data.s3_emission,
          type: "line",
          name: "Scope 3",
        },
      ],
      legend: {
        selected: {
          "Scopes 1+3": false,
          "Scope 1": true,
          "Scope 3": false,
        },
      },
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    if (!max || max < 1) {
      setMax(Math.max(...[].concat(...option.series.map((val) => val.data))));
    }
    option.yAxis.max = max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, scopeOne, max]);

  return (
    <div className="DecarbonizationChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

DecarbonizationChart.propTypes = {
  data: PropTypes.array,
  scopeOne: PropTypes.number,
};

export default DecarbonizationChart;
