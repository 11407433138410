import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SiteEmissionsByCategory.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";

const SiteEmissionsByCategory = ({
  activityTypes,
  activitiesEmissions,
  year,
  scope,
}) => {
  const el = useRef(null);

  useEffect(() => {
    let filteredActivityTypes = [1, 2, 3].includes(Number(scope))
      ? activityTypes.filter((item) => item.scope === scope)
      : activityTypes;

    const categories = filteredActivityTypes.filter((item) => {
      // Temporary hide if consumption unknown activity types.
      const hiddenActivityTypes = [
        "6262893dec289", // Upstream - Leased Assets (If consumption unknown)
        "6262893e129e5", // Downstream - Leased Assets (If consumption unknown)
        "6262893e1a763", // Franchises (If consumption unknown)
      ];
      return !hiddenActivityTypes.includes(item.id);
    });

    const yearSummary = activitiesEmissions.filter(
      (item) => item.yearEnded.substring(0, 4) === year
    );
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      visualMap: {
        show: false,
        min: Math.min(...yearSummary.map((item) => item.totalEmissions)),
        max: Math.max(...yearSummary.map((item) => item.totalEmissions)),
        dimension: 1,
        inRange: { color: ["#b5c334", "#FFCE34", "#eb8146"] },
      },
      xAxis: {
        type: "category",
        data: categories.map((item) => `${item.categoryNumber}. ${item.label}`),
        axisLabel: {
          rotate: 80,
          width: 120,
          overflow: "break",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) =>
            Intl.NumberFormat("en-US", { notation: "compact" }).format(value) +
            " tCO₂e",
        },
      },
      series: {
        type: "bar",
        data: categories.map((category) => {
          const typeSummary = yearSummary
            .filter((summary) => summary.activityType.label === category.label)
            .reduce((acc, currVal) => acc + currVal.totalEmissions, 0);
          return typeSummary;
        }),
      },
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, activitiesEmissions, activityTypes, year, scope]);

  return (
    <div className="SiteEmissionsByCategory">
      <div
        className="site-emissions-category-chart-container me-auto"
        ref={el}
      />
    </div>
  );
};

SiteEmissionsByCategory.propTypes = {
  activitiesEmissions: PropTypes.array.isRequired,
  activityTypes: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  scope: PropTypes.number.isRequired,
};

export default SiteEmissionsByCategory;
