import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

function SiteReviewUpdateActivity({ activity, updateSiteReviewActivity }) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    comment: yup.string().required("Comment is a required field!"),
  });

  const initialValues = {
    comment: "",
  };

  const modalClass = !activity?.isFlaggedMessage
    ? "updateModal"
    : "updateModalWithComment";
  const commentVariant =
    activity?.isFlaggedMessage && activity?.latestException?.isFlagged
      ? "info text-white"
      : "outline-info";

  return (
    <>
      <Button
        size="sm"
        variant={commentVariant}
        className="py-0 mx-1 border border-0"
        onClick={() => handleShow()}
        disabled={
          activity.isFlagged === undefined ||
          (!activity?.isFlagged && !activity?.isFlaggedMessage)
        }
      >
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>
              {!activity?.isFlagged
                ? "Comments History"
                : "Please provide a comment before clearing the exception."}
            </Tooltip>
          }
        >
          <span className="material-icons-outlined md-20">comment</span>
        </OverlayTrigger>
      </Button>

      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleClose}
        size="lg"
        className={modalClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!activity?.isFlagged ? "History" : "Add Comment"}
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) =>
            updateSiteReviewActivity(activity, values?.comment)
          }
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                {!!activity?.isFlaggedComments?.length && (
                  <h4>Comments History</h4>
                )}
                {!!activity?.isFlaggedComments?.length && (
                  <div className="p-2 modalScroll">
                    {activity?.isFlaggedComments?.map((data) => (
                      <>
                        <Badge className="bg-primary mb-2 text-white text-capitalize bg-rounded px-1 me-1">
                          Commented
                        </Badge>
                        by{" "}
                        <span className="text-capitalize">
                          {data?.subscriber?.name}
                        </span>
                        <span className="float-end">
                          <small>
                            {new Date(data?.updatedAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </small>
                        </span>
                        <ul>
                          <li>{data?.body}</li>
                        </ul>
                      </>
                    ))}
                  </div>
                )}
                {activity?.isFlagged ? (
                  <Form.Group controlId="organizationName" className="my-3">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.comment}
                      isInvalid={errors.comment && touched.comment}
                      className="md-6"
                      as="textarea"
                      rows={2}
                      disabled={!activity?.isFlagged}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <></>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                {activity?.isFlagged ? (
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    disabled={!values?.comment || !isValid}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SiteReviewUpdateActivity;
