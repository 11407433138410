import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../PortfolioOrganizationsChart/PortfolioOrganizationsChart.scss";
import * as echarts from "echarts";

const PortfolioOrgNzifChart = ({ nzifStatistics }) => {
  const el = useRef(null);

  useEffect(() => {
    const orgNzifStatistics = nzifStatistics?.map((stat) => {
      if (stat?.aumPercentage) {
        return {
          value: stat?.aumPercentage,
          name: stat?.categoryName,
          itemStyle: {
            color:
              stat?.categoryName === "Net Zero"
                ? "#20284F"
                : stat?.categoryName === "Aligned to a net zero pathway"
                ? "#30568D"
                : stat?.categoryName === "Aligning towards a net zero pathway"
                ? "#25A9E0"
                : stat?.categoryName === "Committed to aligning"
                ? "#F7A520"
                : stat?.categoryName === "Not Aligned"
                ? "#EB5E5D"
                : stat?.categoryName === "Insufficient data" && "#B7B8B8",
          },
        };
      }
    });

    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value
            ? Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(value || 0) + "%"
            : "N/A",
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "70%",
          center: ["50%", "55%"],
          data:
            orgNzifStatistics?.filter((data) => data)?.length !== 0
              ? orgNzifStatistics
              : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.8)",
            },
          },
          label: {
            textStyle: {
              fontSize: 8,
            },
            formatter: "{d}%: {b}",
          },
        },
      ],
    };
    const chart = echarts.init(el.current);

    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, nzifStatistics]);

  return (
    <div className="PortfolioOrganizationsChart">
      <div
        className="portfolio-organizations-chart-container me-auto"
        ref={el}
      />
    </div>
  );
};

PortfolioOrgNzifChart.defaultProps = {
  nzifStatistics: [],
};

PortfolioOrgNzifChart.propTypes = {
  nzifStatistics: PropTypes.array,
};

export default PortfolioOrgNzifChart;
