import { filterByReduction, sum, unionByYear } from "./helper";

export function getMiscDecarbonization(
  array = [],
  growthArray = [],
  scopePreference = 0,
  scopePreferencePercentage = 100,
  secondaryScopePreferencePercentage = 0
) {
  let prevEmission = undefined;
  let prevReductionPercent = undefined;
  let prevEmissionScope3 = undefined;
  let prevReductionPercentScope3 = undefined;
  if (!Array.isArray(array)) return [];

  return array.map(({ year, reduction_percentage }, index, array) => {
    const economicGrowth = growthArray[index];

    prevReductionPercent =
      prevReductionPercent === undefined
        ? reduction_percentage
        : prevReductionPercent + reduction_percentage > 100
        ? 100
        : prevReductionPercent + reduction_percentage;

    prevReductionPercent =
      prevReductionPercent > 100 ? 100 : prevReductionPercent;

    const emissions =
      (prevReductionPercent / 100) *
      economicGrowth?.[`s${scopePreference}_emission`];
    const totalReductionScopeOne =
      (scopePreferencePercentage / 100) * emissions;
    prevEmission =
      economicGrowth?.[`s${scopePreference}_emission`] - totalReductionScopeOne;

    prevReductionPercentScope3 =
      prevReductionPercentScope3 === undefined
        ? reduction_percentage
        : prevReductionPercentScope3 + reduction_percentage > 100
        ? 100
        : prevReductionPercentScope3 + reduction_percentage;

    prevReductionPercentScope3 =
      prevReductionPercentScope3 > 100 ? 100 : prevReductionPercentScope3;

    const emissionsScope3 =
      (prevReductionPercentScope3 / 100) * economicGrowth?.[`s3_emission`];
    const totalReductionScope3 =
      (secondaryScopePreferencePercentage / 100) * emissionsScope3;

    prevEmissionScope3 = economicGrowth?.[`s3_emission`] - totalReductionScope3;

    const totalReduction = sum(totalReductionScopeOne, totalReductionScope3);

    return {
      year: year,
      reduction_percentage: reduction_percentage,
      reduction: totalReductionScopeOne,
      s3_reduction: totalReductionScope3,
      total_reduction: totalReduction,
      emission: prevEmission,
      s3_emission: prevEmissionScope3,
    };
  });
}

export function getTransportDecarbonization(
  array = [],
  growthArray = [],
  impactedPercentScopeOne = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeOne = undefined;
  let prevReductionPercentScopeOne = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s1_reduction_percent }, index, array) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeOne + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeOne + s1_reduction_percent;

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne > 100 ? 100 : prevReductionPercentScopeOne;

    const emissionsScopeOne =
      (prevReductionPercentScopeOne / 100) * economicGrowth?.s1_emission;
    const totalReductionScopeOne =
      (impactedPercentScopeOne / 100) * emissionsScopeOne;

    prevEmissionScopeOne = economicGrowth?.s1_emission - totalReductionScopeOne;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeThree + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s1_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalReduction = sum(prevEmissionScopeOne, prevEmissionScopeThree);
    return {
      year: year,
      cumulativeReduction: prevReductionPercentScopeOne,
      s1_reduction_percent: s1_reduction_percent,
      s1_reduction: totalReductionScopeOne,
      s1_emission: prevEmissionScopeOne,
      s3_reduction_percent: s1_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalReduction,
    };
  });
}

export function getHeatingFuelsDecarbonization(
  array = [],
  growthArray = [],
  impactedPercentScopeOne = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeOne = undefined;
  let prevReductionPercentScopeOne = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s1_reduction_percent }, index, array) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeOne + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeOne + s1_reduction_percent;

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne > 100 ? 100 : prevReductionPercentScopeOne;

    const emissionsScopeOne =
      (prevReductionPercentScopeOne / 100) * economicGrowth?.s1_emission;
    const totalReductionScopeOne =
      (impactedPercentScopeOne / 100) * emissionsScopeOne;

    prevEmissionScopeOne = economicGrowth?.s1_emission - totalReductionScopeOne;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeThree + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s1_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalReduction = sum(prevEmissionScopeOne, prevEmissionScopeThree);

    return {
      year: year,
      cumulativeReduction: prevReductionPercentScopeOne,
      s1_reduction_percent: s1_reduction_percent,
      s1_reduction: totalReductionScopeOne,
      s1_emission: prevEmissionScopeOne,
      s3_reduction_percent: s1_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalReduction,
    };
  });
}

export function getGridDecarbonization(
  array = [],
  growthArray = [],
  impactedPercentScopeTwo = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeTwo = undefined;
  let prevReductionPercentScopeTwo = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s2_reduction_percent }, index, array) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeTwo =
      prevReductionPercentScopeTwo === undefined
        ? s2_reduction_percent
        : prevReductionPercentScopeTwo + s2_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeTwo + s2_reduction_percent;

    prevReductionPercentScopeTwo =
      prevReductionPercentScopeTwo > 100 ? 100 : prevReductionPercentScopeTwo;

    const emissionsScopeTwo =
      (prevReductionPercentScopeTwo / 100) * economicGrowth?.s2_emission;
    const totalReductionScopeTwo =
      (impactedPercentScopeTwo / 100) * emissionsScopeTwo;

    prevEmissionScopeTwo = economicGrowth?.s2_emission - totalReductionScopeTwo;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s2_reduction_percent
        : prevReductionPercentScopeThree + s2_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s2_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalReduction = sum(prevEmissionScopeTwo, prevEmissionScopeThree);

    return {
      year: year,
      cumulativeReduction: prevReductionPercentScopeTwo,
      s2_reduction_percent: s2_reduction_percent,
      s2_reduction: totalReductionScopeTwo,
      s2_emission: prevEmissionScopeTwo,
      s3_reduction_percent: s2_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalReduction,
    };
  });
}

export function getEconomicGrowth(
  array = [],
  scopeOne = 0,
  scopeTwo = 0,
  scopeThree = 0,
  impactedPercent = 100
) {
  let prevScopeOneEmission = undefined;
  let prevScopeTwoEmission = undefined;
  let prevScopeThreeEmission = undefined;

  let prevScopeOneReduction = undefined;
  let prevScopeTwoReduction = undefined;
  let prevScopeThreeReduction = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(
    ({
      year,
      s3_reduction_percent,
      s2_reduction_percent,
      s1_reduction_percent,
      revenue_increase_percent,
    }) => {
      const scopeOneReduction = (s1_reduction_percent / 100) * scopeOne;
      const scopeTwoReduction = (s2_reduction_percent / 100) * scopeTwo;
      const scopeThreeReduction =
        (s1_reduction_percent / 100) * ((scopeThree / 100) * impactedPercent);

      prevScopeOneEmission =
        prevScopeOneEmission === undefined
          ? scopeOne - scopeOneReduction
          : prevScopeOneEmission - scopeOneReduction;

      prevScopeTwoEmission =
        prevScopeTwoEmission === undefined
          ? scopeTwo - scopeTwoReduction
          : prevScopeTwoEmission - scopeTwoReduction;

      prevScopeThreeEmission =
        prevScopeThreeEmission === undefined
          ? scopeThree - scopeThreeReduction
          : prevScopeThreeEmission - scopeThreeReduction;

      prevScopeOneReduction =
        prevScopeOneReduction === undefined
          ? scopeOneReduction
          : prevScopeOneReduction + scopeOneReduction;

      prevScopeTwoReduction =
        prevScopeTwoReduction === undefined
          ? scopeTwoReduction
          : prevScopeTwoReduction + scopeTwoReduction;

      prevScopeThreeReduction =
        prevScopeThreeReduction === undefined
          ? scopeThreeReduction
          : prevScopeThreeReduction + scopeThreeReduction;

      const totalEmission = sum(
        prevScopeOneEmission,
        prevScopeTwoEmission,
        prevScopeThreeEmission
      );

      return {
        year: year,
        revenue_increase_percent: revenue_increase_percent,
        s2_reduction_percent: s2_reduction_percent,
        s2_reduction: prevScopeTwoReduction,
        s2_emission: prevScopeTwoEmission,
        s1_reduction_percent: s1_reduction_percent,
        s1_reduction: prevScopeOneReduction,
        s1_emission: prevScopeOneEmission,
        s3_reduction_percent: s1_reduction_percent,
        s3_reduction: prevScopeThreeReduction,
        s3_emission: prevScopeThreeEmission,
        s1_s2_emissions: sum(prevScopeOneEmission, prevScopeTwoEmission),
        s1_s2_reduction_percent: s1_reduction_percent,
        s1_s2_reduction: sum(prevScopeOneReduction, prevScopeTwoReduction),
        total_saving: sum(
          prevScopeOneEmission,
          prevScopeTwoEmission,
          prevScopeThreeEmission
        ),
        total_emission: totalEmission,
        economicGrowth: totalEmission,
      };
    }
  );
}

export function getMergedSTBi(
  {
    limitReductionPercentage = 0,
    reductionPercentageOne = 0,
    reductionPercentageTwo = 0,
    data = [],
  },
  scopeOne = 0,
  scopeTwo = 0,
  scopeThree = 0
) {
  if (data.length <= 0) return [];

  const half = Math.ceil(data.length / 2);

  if (
    parseFloat(reductionPercentageOne) > 0 &&
    parseFloat(reductionPercentageTwo) > 0 &&
    data.length === 4
  ) {
    const tail = data.slice(half);
    const chunkOne = filterByReduction([tail[0]], reductionPercentageOne, "25");
    const chunkTwo = filterByReduction([tail[1]], reductionPercentageTwo);
    return getScienceBasedTargetInitiative(
      unionByYear(chunkOne, chunkTwo),
      limitReductionPercentage,
      scopeOne,
      scopeTwo,
      scopeThree
    );
  }

  /** Default reductions 2.5% and 4.2% reduction per annum */
  const head = data.length === 4 ? data.slice(0, half) : data;
  const chunkOne = filterByReduction(head, 2.5, "25");
  const chunkTwo = filterByReduction(head, 4.2);

  return getScienceBasedTargetInitiative(
    unionByYear(chunkOne, chunkTwo),
    limitReductionPercentage,
    scopeOne,
    scopeTwo,
    scopeThree
  );
}

function getScienceBasedTargetInitiative(
  collection,
  limitReductionPercentage,
  scopeOne,
  scopeTwo,
  scopeThree
) {
  const sumOfScopes = scopeOne + scopeTwo + scopeThree;
  const limitReduction = ((100 - limitReductionPercentage) / 100) * sumOfScopes;
  let rawValueReductionEmission = undefined;
  let rawValueReductionEmission25 = undefined;

  if (!Array.isArray(collection)) return [];

  return collection.map(
    ({ percentageReduction, percentageReduction25, year, year25, ...rest }) => {
      const emissionReduction = (percentageReduction / 100) * sumOfScopes;
      const emissionReduction25 = (percentageReduction25 / 100) * sumOfScopes;

      rawValueReductionEmission =
        rawValueReductionEmission === undefined
          ? sumOfScopes
          : rawValueReductionEmission - emissionReduction;
      rawValueReductionEmission25 =
        rawValueReductionEmission25 === undefined
          ? sumOfScopes
          : rawValueReductionEmission25 - emissionReduction25;

      const limitConstrainedReductionEmission =
        rawValueReductionEmission <= limitReduction
          ? limitReduction
          : rawValueReductionEmission;
      const limitConstrainedReductionEmission25 =
        rawValueReductionEmission25 <= limitReduction
          ? limitReduction
          : rawValueReductionEmission25;

      return {
        ...rest,
        emissionReduction: emissionReduction,
        emissionReduction25: emissionReduction25,
        limitConstrainedReductionEmission,
        limitConstrainedReductionEmission25,
        percentageReduction,
        percentageReduction25,
        rawValueReductionEmission,
        rawValueReductionEmission25,
        year,
        year25,
      };
    }
  );
}

export function getBusinessAsUsual(array = []) {
  if (!Array.isArray(array)) return [];

  return array.map(({ economicGrowth, scopeTwoReduction, year }) => {
    return {
      year,
      bau: economicGrowth - (scopeTwoReduction || 0),
      gridReduction: scopeTwoReduction || 0,
    };
  });
}

export function getBusinessAsUsualMisc(
  array = [],
  isGridinBAU,
  isHeatingFuelsinBAU,
  isTransportinBAU
) {
  if (!Array.isArray(array)) return [];

  return array.map(
    ({
      economicGrowth,
      scopeTwoReduction,
      scopeOneReductionHeatingFuel,
      scopeOneReductionTransport,
      reduction,
      type,
      year,
      ...rest
    }) => {
      return {
        year,
        [type]:
          economicGrowth -
          sum(
            (isGridinBAU && scopeTwoReduction) || 0,
            (isHeatingFuelsinBAU && scopeOneReductionHeatingFuel) || 0,
            (isTransportinBAU && scopeOneReductionTransport) || 0,
            reduction
          ),
        [`${type}-reduction`]: rest[`${type}-reduction`],
      };
    }
  );
}

export function getBusinessAsUsualTransport(
  isGridinBAU,
  isHeatingFuelsinBAU,
  array = []
) {
  if (!Array.isArray(array)) return [];

  return array.map(
    ({
      economicGrowth,
      scopeOneReduction,
      scopeTwoReduction,
      scopeOneReductionHeatingFuel,
      year,
    }) => {
      return {
        year,
        bauTransport:
          economicGrowth -
          sum(
            scopeOneReduction,
            (isGridinBAU && scopeTwoReduction) || 0,
            (isHeatingFuelsinBAU && scopeOneReductionHeatingFuel) || 0
          ),
        transportReduction: scopeOneReduction || 0,
      };
    }
  );
}

export function getBusinessAsUsualHeatingFuels(isGridinBAU, array = []) {
  if (!Array.isArray(array)) return [];

  return array.map(
    ({ economicGrowth, scopeOneReduction, scopeTwoReduction, year }) => {
      return {
        year,
        bauHeatingFuels:
          economicGrowth -
          sum(scopeOneReduction, (isGridinBAU && scopeTwoReduction) || 0),
        heatingFuelsReduction: scopeOneReduction,
      };
    }
  );
}

export function getMiscDecarbonizationBAU(array = [], growthArray = []) {
  function calculateDecarb(
    array = [],
    growthArray = [],
    scopePreference = 0,
    scopePreferencePercentage = 100,
    secondaryScopePreferencePercentage = 0,
    name
  ) {
    let prevEmission = undefined;
    let prevReductionPercent = undefined;
    let prevEmissionScope3 = undefined;
    let prevReductionPercentScope3 = undefined;
    if (!Array.isArray(array)) return [];

    return array.map(({ year, reduction_percentage }, index, array) => {
      const economicGrowth = growthArray[index];

      prevReductionPercent =
        prevReductionPercent === undefined
          ? reduction_percentage
          : prevReductionPercent + reduction_percentage > 100
          ? 100
          : prevReductionPercent + reduction_percentage;

      prevReductionPercent =
        prevReductionPercent > 100 ? 100 : prevReductionPercent;

      const emissions =
        (prevReductionPercent / 100) *
        economicGrowth?.[`s${scopePreference}_emission`];
      const totalReductionScopeOne =
        (scopePreferencePercentage / 100) * emissions;
      prevEmission =
        economicGrowth?.[`s${scopePreference}_emission`] -
        totalReductionScopeOne;

      prevReductionPercentScope3 =
        prevReductionPercentScope3 === undefined
          ? reduction_percentage
          : prevReductionPercentScope3 + reduction_percentage > 100
          ? 100
          : prevReductionPercentScope3 + reduction_percentage;

      prevReductionPercentScope3 =
        prevReductionPercentScope3 > 100 ? 100 : prevReductionPercentScope3;

      const emissionsScope3 =
        (prevReductionPercentScope3 / 100) * economicGrowth?.[`s3_emission`];
      const totalReductionScope3 =
        (secondaryScopePreferencePercentage / 100) * emissionsScope3;

      prevEmissionScope3 =
        economicGrowth?.[`s3_emission`] - totalReductionScope3;

      const totalReduction = sum(totalReductionScopeOne, totalReductionScope3);

      return {
        year: year,
        [`${name}-reduction`]: totalReduction,
        reduction: totalReductionScopeOne,
        s3_reduction: totalReductionScope3,
        [name]: prevEmission + prevEmissionScope3,
        emission: prevEmission,
        s3_emission: prevEmissionScope3,
        type: name,
      };
    });
  }

  if (!Array.isArray(array)) return [];

  return array.map((value, index) => {
    return calculateDecarb(
      value.data,
      growthArray,
      value.scopePreference,
      value.scopePreferencePercentage,
      value.secondaryScopePreferencePercentage,
      value.type
    );
  });
}

export function getTransportDecarbonizationBAU(
  array = [],
  growthArray = [],
  impactedPercentScopeOne = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeOne = undefined;
  let prevReductionPercentScopeOne = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s1_reduction_percent }, index) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeOne + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeOne + s1_reduction_percent;

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne > 100 ? 100 : prevReductionPercentScopeOne;

    const emissionsScopeOne =
      (prevReductionPercentScopeOne / 100) * economicGrowth?.s1_emission;
    const totalReductionScopeOne =
      (impactedPercentScopeOne / 100) * emissionsScopeOne;

    prevEmissionScopeOne = economicGrowth?.s1_emission - totalReductionScopeOne;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeThree + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s1_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalEmission = sum(prevEmissionScopeOne, prevEmissionScopeThree);
    const totalReduction = sum(
      totalReductionScopeOne,
      totalReductionScopeThree
    );

    return {
      year: year,
      s1_reduction_percent: s1_reduction_percent,
      s1_reduction: totalReductionScopeOne,
      s1_emission: prevEmissionScopeOne,
      s3_reduction_percent: s1_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalEmission,
      decarbOfTransport: totalEmission,
      scopeOneReduction: totalReduction,
    };
  });
}

export function getHeatingFuelsDecarbonizationBAU(
  array = [],
  growthArray = [],
  impactedPercentScopeOne = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeOne = undefined;
  let prevReductionPercentScopeOne = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s1_reduction_percent }, index) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeOne + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeOne + s1_reduction_percent;

    prevReductionPercentScopeOne =
      prevReductionPercentScopeOne > 100 ? 100 : prevReductionPercentScopeOne;

    const emissionsScopeOne =
      (prevReductionPercentScopeOne / 100) * economicGrowth?.s1_emission;
    const totalReductionScopeOne =
      (impactedPercentScopeOne / 100) * emissionsScopeOne;

    prevEmissionScopeOne = economicGrowth?.s1_emission - totalReductionScopeOne;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s1_reduction_percent
        : prevReductionPercentScopeThree + s1_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s1_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalEmission = sum(prevEmissionScopeOne, prevEmissionScopeThree);
    const totalReduction = sum(
      totalReductionScopeOne,
      totalReductionScopeThree
    );

    return {
      year: year,
      s1_reduction_percent: s1_reduction_percent,
      s1_reduction: totalReductionScopeOne,
      s1_emission: prevEmissionScopeOne,
      s3_reduction_percent: s1_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalEmission,
      decarbOfHeatingFuels: totalEmission,
      scopeOneReduction: totalReduction,
    };
  });
}

export function getGridDecarbonizationBAU(
  array = [],
  growthArray = [],
  impactedPercentScopeTwo = 100,
  impactedPercentScopeThree = 100
) {
  let prevEmissionScopeTwo = undefined;
  let prevReductionPercentScopeTwo = undefined;
  let prevEmissionScopeThree = undefined;
  let prevReductionPercentScopeThree = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(({ year, s2_reduction_percent }, index, array) => {
    const economicGrowth = growthArray[index];

    prevReductionPercentScopeTwo =
      prevReductionPercentScopeTwo === undefined
        ? s2_reduction_percent
        : prevReductionPercentScopeTwo + s2_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeTwo + s2_reduction_percent;

    prevReductionPercentScopeTwo =
      prevReductionPercentScopeTwo > 100 ? 100 : prevReductionPercentScopeTwo;

    const emissionsScopeTwo =
      (prevReductionPercentScopeTwo / 100) * economicGrowth?.s2_emission;
    const totalReductionScopeTwo =
      (impactedPercentScopeTwo / 100) * emissionsScopeTwo;

    prevEmissionScopeTwo = economicGrowth?.s2_emission - totalReductionScopeTwo;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree === undefined
        ? s2_reduction_percent
        : prevReductionPercentScopeThree + s2_reduction_percent > 100
        ? 100
        : prevReductionPercentScopeThree + s2_reduction_percent;

    prevReductionPercentScopeThree =
      prevReductionPercentScopeThree > 100
        ? 100
        : prevReductionPercentScopeThree;

    const emissionsScopeThree =
      (prevReductionPercentScopeThree / 100) * economicGrowth?.s3_emission;
    const totalReductionScopeThree =
      (impactedPercentScopeThree / 100) * emissionsScopeThree;

    prevEmissionScopeThree =
      economicGrowth?.s3_emission - totalReductionScopeThree;

    const totalEmission = sum(prevEmissionScopeTwo, prevEmissionScopeThree);
    const totalReduction = sum(
      totalReductionScopeTwo,
      totalReductionScopeThree
    );

    return {
      year: year,
      s2_reduction_percent: s2_reduction_percent,
      s2_reduction: totalReductionScopeTwo,
      s2_emission: prevEmissionScopeTwo,
      s3_reduction_percent: s2_reduction_percent,
      s3_reduction: totalReductionScopeThree,
      s3_emission: prevEmissionScopeThree,
      emission: totalEmission,
      gridDecarbonization: totalEmission,
      scopeTwoReduction: totalReduction,
    };
  });
}

export function getBAUFunnelModel(array = [], scopeOne = 0, scopeTwo = 0) {
  let prevScopeOneEmission = undefined;
  let prevScopeTwoEmission = undefined;

  if (!Array.isArray(array)) return [];

  return array.map(
    ({
      year,
      s2_reduction_percent,
      s1_reduction_percent,
      revenue_increase_percent,
    }) => {
      const scopeOneReduction = (s1_reduction_percent / 100) * scopeOne;
      const scopeTwoReduction = (s2_reduction_percent / 100) * scopeTwo;

      prevScopeOneEmission =
        prevScopeOneEmission === undefined
          ? scopeOne - scopeOneReduction
          : prevScopeOneEmission - scopeOneReduction;

      prevScopeTwoEmission =
        prevScopeTwoEmission === undefined
          ? scopeTwo - scopeTwoReduction
          : prevScopeTwoEmission - scopeTwoReduction;

      return {
        year: year,
        revenue_increase_percent: revenue_increase_percent,
        s2_reduction_percent: s2_reduction_percent,
        s2_reduction: scopeTwoReduction,
        s2_emission: prevScopeTwoEmission,
        s1_reduction_percent: s1_reduction_percent,
        s1_reduction: scopeOneReduction,
        s1_emission: prevScopeOneEmission,
        total_saving: sum(scopeOneReduction, scopeTwoReduction),
        total_emission: sum(prevScopeOneEmission, prevScopeTwoEmission),
      };
    }
  );
}
