import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Form, Alert, Table, Spinner } from "react-bootstrap";

import { put, get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const PortfolioPermissioning = ({ account, member, portfolios }) => {
  const subscribedPromises = useRef([]);

  const [memberPortfolios, setMemberPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchMemberPortfolios = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let fetchMemberPortfolios = get(`members/${member.id}/portfolios`, {
      params: { accountId: account.id },
    });

    fetchMemberPortfolios.promise
      .then((response) => {
        setMemberPortfolios(response.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fetchMemberPortfolios);
  }, [member.id, account.id]);

  const updateMemberPortfolios = (memberPortfolios) => {
    setError(null);
    setIsLoading(true);

    const updatePortfolios = put(`members/${member.id}/portfolios`, {
      accountId: account.id,
      portfolioIds: memberPortfolios.map((portfolio) => portfolio.id),
    });

    updatePortfolios.promise
      .then(() => {
        setMemberPortfolios([...memberPortfolios]);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchMemberPortfolios();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchMemberPortfolios]);

  return (
    <>
      <div className="p-3 border-start border-end border-bottom">
        <p>Select portfolios to provide access to the member.</p>

        {!portfolios.length ? (
          <Alert variant="info">
            There are currently no portfolios to show. Add portfolios to assign
            access.
          </Alert>
        ) : (
          <Table responsive hover size="sm">
            <thead>
              <tr>
                <th>Portfolio</th>
                <th className="text-end">Can Access</th>
              </tr>
            </thead>

            <tbody>
              {(() => {
                let allPortfoliosAccess =
                  memberPortfolios.length === portfolios.length;
                return (
                  <tr className="bg-light border-dark border-2">
                    <td>All Portfolios</td>
                    <td className="text-end">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        value={allPortfoliosAccess}
                        checked={allPortfoliosAccess}
                        onChange={(ev) => {
                          if (allPortfoliosAccess) updateMemberPortfolios([]);
                          else updateMemberPortfolios([...portfolios]);
                        }}
                      />
                      {isLoading && <Spinner />}
                      {error && <ErrorHandler error={error} />}
                    </td>
                  </tr>
                );
              })()}
              {portfolios.map((portfolio) => {
                let canAcess =
                  memberPortfolios &&
                  !!memberPortfolios.find((item) => item.id === portfolio.id);
                return (
                  <Fragment key={portfolio.id + canAcess}>
                    <tr>
                      <td>{portfolio.name}</td>
                      <td className="text-end">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          value={canAcess}
                          checked={canAcess}
                          onChange={(ev) => {
                            if (canAcess)
                              updateMemberPortfolios([
                                ...memberPortfolios.filter(
                                  (item) => item.id !== portfolio.id
                                ),
                              ]);
                            else
                              updateMemberPortfolios([
                                ...memberPortfolios,
                                portfolio,
                              ]);
                          }}
                        />
                        {isLoading && <Spinner />}
                        {error && <ErrorHandler error={error} />}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

PortfolioPermissioning.propTypes = {
  account: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  portfolios: PropTypes.array.isRequired,
};

export default PortfolioPermissioning;
