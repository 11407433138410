import { Container } from "react-bootstrap";

const Disclaimer = () => {
  return (
    <Container>
      <div className="d-flex mb-2">
        <h5>ERM Helpdesk: </h5>
        <a className="mx-1" href="mailto:support.nzc@erm.com">
          <h5>support.nzc@erm.com</h5>
        </a>
      </div>
      <h5>Disclaimer and IP</h5>
      <p className="text-muted">
        <small>
          The methods contained within the Net Zero Compass software product are
          protected and owned by ERM Information Solutions, Inc.  No parties may
          copy, reproduce or otherwise transit the data or methods contained in
          this platform without prior written consent of ERM.  ERM is not
          responsible for how this platform is used by clients or any third
          parties and we disclaim any responsibility for reliance on the results
          which depend on user inputs.  We recommend that users perform quality
          assurance of the results before use in any reporting or to support any
          Net Zero investment decisions.
        </small>
      </p>
    </Container>
  );
};

export default Disclaimer;
