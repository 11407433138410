import React, { useEffect, useState } from "react";
import { Modal, Button, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

function LockUnlockHistory({ year, siteLockUnlockDetail }) {
  const [show, setShow] = useState(false);
  const [history, setHistory] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const filterHistory = siteLockUnlockDetail?.filter(
      (data) => new Date(data?.year).getFullYear() === Number(year)
    );
    setHistory(
      !!filterHistory?.length ? filterHistory?.[0]?.auditActivityHistory : []
    );
  }, [year, siteLockUnlockDetail]);

  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip>
            {!history?.length ? "History not available" : "History"}
          </Tooltip>
        }
      >
        <span>
          <Button
            size="sm"
            variant="outline-primary"
            className="py-0"
            onClick={() => handleShow()}
            disabled={!history?.length}
          >
            <span className="material-icons-outlined md-20">history</span>
          </Button>
        </span>
      </OverlayTrigger>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lock / Unlock History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2 historyModalScroll">
            {history?.map((data) => (
              <div>
                <Badge className="bg-primary mb-2 text-white text-capitalize bg-rounded px-1 me-1">
                  {data?.status === 1 || data?.status === 3 ? "Unlock" : "Lock"}
                </Badge>
                by Admin{" "}
                <span className="text-capitalize">{data?.subscriber}</span>
                <span className="float-end">
                  <small>
                    {new Date(data?.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </span>
                {data?.comment ? (
                  <ul>
                    <li>{data?.comment}</li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="link" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LockUnlockHistory;
