import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { useContentContext } from "contexts/ContentContext";

import Header from "../Header/Header";

import "./WhatsNew.scss";
import { WhatsNewBody } from "./WhatsNew";

const AllArticles = () => {
  const { allFilteredContent, bookmarkableIds, isLoading, error } =
    useContentContext();

  return (
    <>
      <Header title="" />
      <Container className="mb-5 mt-3 WhatsNew">
        <Row>
          <Col xs={12} md={12} className="mt-3 mb-3 py-3">
            <div>
              <h1>What's New</h1>
              <hr />
              {error && <ErrorHandler error={error} />}

              {isLoading ? (
                <Loader />
              ) : (
                <div className="mt-4">
                  <WhatsNewBody
                    filteredContent={allFilteredContent}
                    bookmarkableIds={bookmarkableIds}
                    allUpdates={true}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AllArticles;
