import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./PortfolioEmissionsChart.scss";
import * as echarts from "echarts";

const PortfolioEmissionsChart = ({ portfolios, onChartRendered }) => {
  const el = useRef(null);

  useEffect(() => {
    const formattedEmissions = !!portfolios?.length
      ? portfolios?.map((portfolio) => {
          return {
            value: portfolio.financedEmissionsPercentage,
            name: portfolio.name,
          };
        })
      : [];

    const option = {
      color: [
        "#b5c334",
        "#fac858",
        "#eb8146",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value
            ? Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(value) + "%"
            : "0%",
      },
      legend: {
        show: false,
      },

      series: [
        {
          name: "",
          type: "pie",
          radius: "70%",
          center: ["50%", "55%"],
          data: formattedEmissions,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.8)",
            },
          },
          label: {
            position: "outer",
            alignTo: "none",
            textStyle: {
              fontSize: 8,
            },
            formatter: "{d}%: {b}",
          },
        },
      ],
    };
    const chart = echarts.init(el.current);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered && onChartRendered(src);
    });

    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, portfolios]);

  return (
    <div className="PortfoliosChart">
      <div className="portfolios-emissions-chart-container me-auto" ref={el} />
    </div>
  );
};

function PortfolioEmissions({ portfolios = [] }) {
  if (portfolios.length > 0) {
    return <PortfolioEmissionsChart portfolios={portfolios} />;
  } else {
    return <span />;
  }
}

PortfolioEmissions.defaultProps = {
  portfolios: [],
};

PortfolioEmissions.propTypes = {
  portfolios: PropTypes.array,
};

export default PortfolioEmissions;
