import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SiteEmissionsByActivity.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";

const SiteEmissionsByActivity = ({ activitiesEmissions, scope }) => {
  const el = useRef(null);

  useEffect(() => {
    const scopeSummary = activitiesEmissions.filter(
      (item) => item.activityType.scope === Number(scope)
    );

    const emissionsYears = [];
    let endDate = new Date();
    let currentYear = endDate.getFullYear();
    while (emissionsYears.length <= 10) {
      const year = currentYear--;
      emissionsYears.push(year.toString());
    }
    emissionsYears.reverse();

    const activitiesSeries = scopeSummary
      .sort((a, b) => b.totalEmissions - a.totalEmissions)
      .map((activitySummary) => {
        return {
          name: activitySummary.activityType?.title,
          type: "bar",
          stack: "activityType",
          barMaxWidth: 60,

          emphasis: { focus: "series" },
          data: emissionsYears.map((year) => {
            let summary =
              activitySummary.yearEnded.substring(0, 4) === year
                ? activitySummary
                : null;
            return {
              value: summary?.totalEmissions,
              itemStyle: { opacity: 0.8 },
            };
          }),
        };
      });

    const series = [...activitiesSeries];

    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      color: [
        "#fe8463",
        "#9bca63",
        "#fad860",
        "#f3a43b",
        "#60c0dd",
        "#d7504b",
        "#c6e579",
        "#f4e001",
        "#f0805a",
        "#26c0c0",
      ],
      legend: { type: "scroll" },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      xAxis: {
        type: "category",
        data: emissionsYears.map((item) => item.substring(0, 4)),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) =>
            Intl.NumberFormat("en-US", { notation: "compact" }).format(value) +
            " tCO₂e",
        },
      },
      series: series,
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, activitiesEmissions, scope]);

  return (
    <div className="SiteEmissionsByActivity">
      <div
        className="site-emissions-activity-chart-container me-auto"
        ref={el}
      />
    </div>
  );
};

SiteEmissionsByActivity.propTypes = {
  activitiesEmissions: PropTypes.array.isRequired,
  scope: PropTypes.number.isRequired,
};

export default SiteEmissionsByActivity;
