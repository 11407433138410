import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./CarbonIntensityByYear.scss";
import * as echarts from "echarts";
import { getFormattedEmission } from "utils/StringUtils";
import { groupBy } from "lodash";
import { Form } from "react-bootstrap";

function CarbonIntensityByYear({
  carbonIntensity,
  carbonIntensityEnergy,
  onChartRendered,
}) {
  const [filter, setFilter] = useState("revenue");
  const el = useRef(null);

  let energyByYear = groupBy(
    [...carbonIntensityEnergy, ...carbonIntensity],
    (item) => item.yearEnded
  );
  let energyYears = Object.keys(energyByYear).sort(
    (a, b) => Number(a) - Number(b)
  );

  let revenueData = energyYears.map((year) => {
    return carbonIntensity.find((item) => item.yearEnded === year)
      ?.carbonIntensity;
  });

  let energyData = energyYears.map((year) => {
    let yearEnergyEntries = carbonIntensityEnergy.filter(
      (item) => item.yearEnded === year
    );
    let yearEmissions = yearEnergyEntries.reduce(
      (sum, entry) => sum + entry.emissions || 0,
      0
    );

    let yearEnergy = yearEnergyEntries.reduce(
      (sum, entry) => sum + entry.energy || 0,
      0
    );
    let energyI =
      yearEmissions && yearEnergy ? yearEmissions / yearEnergy : null;

    return energyI;
  });

  let data = filter === "energy" ? energyData : revenueData;
  let units = filter === "energy" ? "MMTCO₂e/MJ" : "tCO₂e/$MM";

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} ${units}` : "N/A",
        axisPointer: { type: "shadow" },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top: "20%",
        containLabel: true,
      },
      yAxis: {
        name: `${units}`,
        type: "value",
        scale: true,
        splitNumber: 3,
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          fontSize: 10,
        },
      },
      xAxis: {
        type: "category",
        data: energyYears.map((item) => item.substring(0, 4)),
      },
      series: [
        {
          name: "Carbon Intensity",
          type: "line",

          itemStyle: {
            color: "#eb8146",
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#eb8146" },
              { offset: 1, color: "#b5c334" },
            ]),
          },
          data: data,
        },
      ],
    };
    const chart = echarts.init(el.current);
    chart.setOption(option, true);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });
      onChartRendered(src);
    });
    return () => {
      chart.clear();
    };
  }, [el, filter]);

  return (
    <>
      <Form.Select
        aria-label="filter"
        size="sm"
        value={filter || ""}
        onChange={(ev) => {
          setFilter(ev.target.value);
        }}
      >
        {["revenue"].map((filter) => (
          <option key={filter} value={filter}>
            {filter === "energy"
              ? "Emissions to Energy Intensity"
              : "Emissions to Revenue Intensity"}
          </option>
        ))}
      </Form.Select>
      <div className="CarbonIntensityByYearChart">
        <div className="carbon-intensity-chart-container me-auto" ref={el} />
      </div>
    </>
  );
}

CarbonIntensityByYear.propTypes = {
  carbonIntensity: PropTypes.array.isRequired,
  carbonIntensityEnergy: PropTypes.array.isRequired,
};

export default CarbonIntensityByYear;
