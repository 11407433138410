import React, { useEffect, useState } from "react";
import { Alert, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

import { fromSnakeToTitleCase } from "utils/StringUtils";

const SiteReviewWorkflowManagement = ({
  year,
  handleManageReviewWorkflow,
  siteReviewWorkflow,
}) => {
  const [reviewStages, setReviewStages] = useState([
    { name: "Manager Review", stage: 1, status: 1 },
    { name: "Internal Audit", stage: 2, status: 1 },
    { name: "Assurance Review", stage: 3, status: 1 },
  ]);

  useEffect(() => {
    if (
      siteReviewWorkflow.auditActivityHistory &&
      siteReviewWorkflow.auditActivityHistory.length > 0
    ) {
      const latestStageOne = siteReviewWorkflow.auditActivityHistory.find(
        (auditHistory) => auditHistory.stage === 1
      );
      const latestStageTwo = siteReviewWorkflow.auditActivityHistory.find(
        (auditHistory) => auditHistory.stage === 2
      );
      const latestStageThree = siteReviewWorkflow.auditActivityHistory.find(
        (auditHistory) => auditHistory.stage === 3
      );

      setReviewStages([
        {
          name: "Manager Review",
          stage: 1,
          status: latestStageOne?.status || 1,
        },
        {
          name: "Internal Audit",
          stage: 2,
          status: latestStageTwo?.status || 1,
        },
        {
          name: "Assurance Review",
          stage: 3,
          status: latestStageThree?.status || 1,
        },
      ]);
    }
  }, [siteReviewWorkflow]);

  const getStatusIconName = (status) => {
    switch (Number.parseInt(status)) {
      case 1:
        return "pending";
      case 2:
        return "loop";
      case 3:
        return "check_circle";
      default:
        return "";
    }
  };

  const getStatusIconClass = (status) => {
    switch (Number.parseInt(status)) {
      case 1:
        return "black";
      case 2:
        return "info";
      case 3:
        return "primary";
      default:
        return "";
    }
  };

  const getStatusName = (status) => {
    switch (Number.parseInt(status)) {
      case 1:
        return "unstarted";
      case 2:
        return "started";
      case 3:
        return "completed";
      default:
        return "";
    }
  };

  return (
    <div className="row">
      {reviewStages.map((reviewStage) => (
        <div className="col-md-4" key={reviewStage.name}>
          <Card className="shadow">
            <Card.Body>
              <Card.Title className="fw-bold fs-3">
                {reviewStage.name}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                For reporting year {year}
              </Card.Subtitle>
              <Card.Text className="text-center">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      {reviewStage.name} {reviewStage.status}
                    </Tooltip>
                  }
                >
                  <span
                    className={`material-icons-outlined md-72 text-${getStatusIconClass(
                      reviewStage.status
                    )}`}
                  >
                    {getStatusIconName(reviewStage.status)}
                  </span>
                </OverlayTrigger>
              </Card.Text>
              <Form.Group controlId="activity-year" className="my-3">
                <Form.Label>Update status</Form.Label>
                <Form.Select
                  aria-label="review-status"
                  size="sm"
                  className="ps-3"
                  value={reviewStage.status}
                  onChange={(ev) => {
                    const selectedStatus = ev.target.value;
                    handleManageReviewWorkflow(
                      reviewStage.stage,
                      selectedStatus,
                      siteReviewWorkflow.id
                    );
                    const updatedReviewStages = reviewStages.map((stg) => {
                      if (stg.name === reviewStage.name) {
                        return { ...reviewStage, status: selectedStatus };
                      }
                      return stg;
                    });
                    setReviewStages(updatedReviewStages);
                  }}
                >
                  {[
                    { label: "unstarted", value: 1 },
                    { label: "started", value: 2 },
                    { label: "completed", value: 3 },
                  ].map((option) => (
                    <option key={option.label} value={option.value}>
                      {fromSnakeToTitleCase(option.label)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <hr />
              <h3>History</h3>
              <ul className="timeline">
                {siteReviewWorkflow.auditActivityHistory
                  .filter((h) => h.stage === reviewStage.stage)
                  .map((history) => (
                    <li key={`${siteReviewWorkflow.id}-${history.updatedAt}`}>
                      <span>Review {getStatusName(history.status)}, </span>
                      <span>
                        {new Date(history.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </span>
                      <p>By {history?.subscriber} (Admin)</p>
                    </li>
                  ))}
              </ul>
              {siteReviewWorkflow.auditActivityHistory.filter(
                (h) => h.stage === reviewStage.stage
              ).length === 0 && (
                <Alert variant="info">No history for this year</Alert>
              )}
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
};

SiteReviewWorkflowManagement.defaultProps = {
  siteReviewWorkflow: {
    id: "",
    auditActivityHistory: [],
  },
};

SiteReviewWorkflowManagement.propTypes = {
  year: PropTypes.string.isRequired,
  handleManageReviewWorkflow: PropTypes.func.isRequired,
  siteReviewWorkflow: PropTypes.shape({
    id: PropTypes.string,
    auditActivityHistory: PropTypes.arrayOf(
      PropTypes.shape({
        stage: PropTypes.number,
        status: PropTypes.number,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
};

export default SiteReviewWorkflowManagement;
