import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { put, get } from "utils/DeApi";
import NumberFormat from "react-number-format";
import { getAllYearsBetweenDates } from "utils/dateUtils";

const NzifEdit = ({
  organization,
  setOnNzifDetailUpdated,
  nzifYear,
  nzifInfo,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedAssetType, setSelectedAssetType] = useState(
    nzifInfo?.[0]?.assetType === 1 ? "infrastructure" : "nonInfrastructure"
  );
  const [categories, setCategories] = useState([]);
  const [currentCatId, setCurrentCatId] = useState("");

  const subscribedPromises = useRef([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    year: yup.string().required("Reporting Year is required"),
    assetType: yup.string().required("Asset Type is Required"),
    operationValue:
      selectedAssetType === "infrastructure" &&
      yup
        .number()
        .required("% of Total Value is Required")
        .min(0)
        .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`),
    constructionValue:
      selectedAssetType === "infrastructure" &&
      yup
        .number()
        .required("% of Total Value is Required")
        .min(0)
        .max(
          100,
          `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
        ),
    completedValue: yup.number(),
    operationCategory:
      selectedAssetType === "infrastructure" &&
      yup.string().required("Category is Required"),
    constructionCategory:
      selectedAssetType === "infrastructure" &&
      yup.string().required("Category is Required"),
    completedCategory:
      selectedAssetType === "nonInfrastructure" &&
      yup.string().required("Category is Required"),
  });

  const fetchNzifCategories = () => {
    setError(null);
    setIsLoading(true);

    const nzifCategoryPromise = get("nzif-categories");
    nzifCategoryPromise.promise
      .then((response) => {
        setCategories(response.data);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(nzifCategoryPromise);
  };

  useEffect(() => {
    fetchNzifCategories();
  }, []);

  useEffect(() => {
    const id = categories?.filter((data) =>
      data?.name?.includes("Insufficient")
    );
    setCurrentCatId(id?.[0]?.id);
  }, [categories]);

  const updateNzifDetail = ({
    year,
    operationValue,
    operationCategory,
    constructionValue,
    constructionCategory,
    completedValue,
    completedCategory,
  }) => {
    setError(null);
    setIsLoading(true);

    const nzifDetailBody =
      selectedAssetType === "infrastructure"
        ? {
            assetType: 1,
            yearEnded: year + "-12-31",
            organizationId: organization?.id,
            assets: [
              {
                assetStatus: "1",
                nzifCategoryId: operationCategory,
                assetPercentage: operationValue,
              },
              {
                assetStatus: "2",
                nzifCategoryId: constructionCategory,
                assetPercentage: constructionValue,
              },
            ],
          }
        : selectedAssetType === "nonInfrastructure" && {
            assetType: 2,
            yearEnded: year + "-12-31",
            organizationId: organization?.id,
            assets: [
              {
                assetStatus: "",
                nzifCategoryId: completedCategory,
                assetPercentage: completedValue,
              },
            ],
          };

    const nzifDetailPromise = put(
      `/organization-nzif-details/${nzifInfo?.[0]?.id}`,
      nzifDetailBody
    );

    nzifDetailPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        setOnNzifDetailUpdated(true);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="right py-0 me-1"
        size="sm"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>

      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleClose}
        size="lg"
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              {organization.name}
              <br />
            </small>
            Assign NZIF Category
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateNzifDetail(values)}
          initialValues={{
            year: nzifYear?.slice(0, 4) || "",
            assetType:
              nzifInfo?.[0]?.assetType === 1
                ? "infrastructure"
                : "nonInfrastructure",
            operationValue:
              (nzifInfo?.length > 1 && nzifInfo?.[0]?.assetPercentage) || "0",
            operationCategory:
              (nzifInfo?.length > 1 && nzifInfo?.[0]?.nzifCategory?.id) || "",
            constructionValue:
              (nzifInfo?.length > 1 && nzifInfo?.[1]?.assetPercentage) || "100",
            constructionCategory:
              (nzifInfo?.length > 1 && nzifInfo?.[1]?.nzifCategory?.id) || "",
            completedValue: "100",
            completedCategory:
              (nzifInfo?.length === 1 && nzifInfo?.[0]?.nzifCategory?.id) ||
              currentCatId,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => {
            const checkForValid =
              (values?.assetType === "nonInfrastructure" &&
              values?.completedCategory &&
              values?.completedValue
                ? true
                : false) ||
              (values?.assetType === "infrastructure" &&
              values?.operationValue &&
              values?.operationCategory &&
              values?.constructionValue &&
              values?.constructionCategory
                ? true
                : false);
            return (
              <Form>
                <Modal.Body>
                  <Form.Group controlId="activity-year" className="my-3">
                    <Form.Label>Reporting Year</Form.Label>
                    <Field
                      as={Form.Select}
                      name="year"
                      value={values.year}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                      isValid={values.year}
                    >
                      <option>Select year</option>
                      {getAllYearsBetweenDates()?.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Field>
                    <Form.Control.Feedback type="invalid">
                      {errors.year}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {values.year && (
                    <>
                      <Form.Group controlId="assetType" className="my-3">
                        <Form.Label>Asset Type</Form.Label>
                        <Form.Select
                          aria-label="asset-filter"
                          name="assetType"
                          value={values.assetType}
                          onChange={(ev) => {
                            setFieldValue("assetType", ev.target.value);
                            setSelectedAssetType(ev.target.value);
                          }}
                          onBlur={handleBlur}
                          isValid={values.assetType}
                          isInvalid={errors.assetType && touched.assetType}
                        >
                          <option disabled={values.assetType}>
                            Select Asset Type
                          </option>
                          <option value="infrastructure">Infrastructure</option>
                          <option value="nonInfrastructure">
                            Non Infrastructure
                          </option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.assetType}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {values?.assetType === "infrastructure" ? (
                        <>
                          <h4>Asset Breakdown</h4>
                          <Form.Group controlId="operationValue">
                            <Form.Label>
                              % of value for asset in operation
                            </Form.Label>
                            <NumberFormat
                              customInput={Form.Control}
                              value={values.operationValue}
                              onValueChange={(numberItem) => {
                                setFieldValue(
                                  "operationValue",
                                  numberItem.value
                                );
                                Number(numberItem.value) <= 100
                                  ? setFieldValue(
                                      "constructionValue",
                                      100 - Number(numberItem.value)
                                    )
                                  : setFieldValue("constructionValue", "");
                              }}
                              name="operationValue"
                              placeholder="Enter % of Total Value"
                              onBlur={handleBlur}
                              isValid={values.operationValue}
                              isInvalid={
                                errors.operationValue && touched.operationValue
                              }
                              className="md-6"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.operationValue}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            controlId="operationCategory"
                            className="my-3"
                          >
                            <Form.Label>
                              NZIF category for asset in operation
                            </Form.Label>
                            <Form.Select
                              aria-label="category-filter"
                              name="operationCategory"
                              value={values.operationCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={values.operationCategory}
                              isInvalid={
                                errors.operationCategory &&
                                touched.operationCategory
                              }
                            >
                              <option disabled={values.operationCategory}>
                                Select NZIF Category
                              </option>
                              {categories?.map((data) => (
                                <option value={data?.id}>{data?.name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.operationCategory}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            controlId="operationValue"
                            className="my-3"
                          >
                            <Form.Label>
                              % of value for asset under construction
                            </Form.Label>
                            <NumberFormat
                              customInput={Form.Control}
                              value={values.constructionValue}
                              onValueChange={(numberItem) => {
                                setFieldValue(
                                  "constructionValue",
                                  numberItem.value
                                );
                              }}
                              name="constructionValue"
                              placeholder="Enter % of Total Value"
                              onBlur={handleBlur}
                              isValid={values.constructionValue}
                              isInvalid={
                                errors.constructionValue &&
                                touched.constructionValue
                              }
                              className="md-6"
                              disabled
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.constructionValue}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="operationCategory">
                            <Form.Label>
                              NZIF category for asset under construction
                            </Form.Label>
                            <Form.Select
                              aria-label="category-filter"
                              name="constructionCategory"
                              value={values.constructionCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={values.constructionCategory}
                              isInvalid={
                                errors.constructionCategory &&
                                touched.constructionCategory
                              }
                            >
                              <option disabled={values.constructionCategory}>
                                Select NZIF Category
                              </option>
                              {categories?.map((data) => (
                                <option value={data?.id}>{data?.name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.constructionCategory}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      ) : (
                        values?.assetType === "nonInfrastructure" && (
                          <>
                            <Form.Group controlId="operationValue">
                              <Form.Label>% of Total Value</Form.Label>
                              <NumberFormat
                                customInput={Form.Control}
                                value={values.completedValue}
                                onValueChange={(numberItem) => {
                                  setFieldValue(
                                    "completedValue",
                                    numberItem.value
                                  );
                                }}
                                name="completedValue"
                                placeholder="Enter % of Total Value"
                                onBlur={handleBlur}
                                isValid={values.completedValue}
                                isInvalid={
                                  errors.completedValue &&
                                  touched.completedValue
                                }
                                disabled
                                className="md-6"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.completedValue}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              controlId="operationCategory"
                              className="my-3"
                            >
                              <Form.Label>NZIF Category</Form.Label>
                              <Form.Select
                                aria-label="category-filter"
                                name="completedCategory"
                                value={values?.completedCategory}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={values?.completedCategory}
                                isInvalid={
                                  errors?.completedCategory &&
                                  touched?.completedCategory
                                }
                              >
                                <option disabled={values?.completedCategory}>
                                  Select NZIF Category
                                </option>
                                {categories?.map((data) => (
                                  <option value={data?.id}>{data?.name}</option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors?.completedCategory}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </>
                        )
                      )}
                    </>
                  )}

                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader />}
                </Modal.Body>

                <Modal.Footer>
                  <Button size="sm" variant="link" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    disabled={!checkForValid || !values?.year}
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

NzifEdit.propTypes = {
  organization: PropTypes.object.isRequired,
  nzifYear: PropTypes.string.isRequired,
  nzifInfo: PropTypes.array.isRequired,
  setOnNzifDetailUpdated: PropTypes.func.isRequired,
};

export default NzifEdit;
