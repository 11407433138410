import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./NoMatch.css";

/**
 * 404: No match route
 */
const NoMatch = () => {
  const location = useLocation();
  return (
    <Col xs={12}>
      <div className="text-center pt-5 pb-5">
        <p>Oops! We couldn't find this page!</p>
        <code className="text-danger">
          404
          <br />
          <small>No match for {location.pathname}</small>
        </code>

        <h3 className="mt-3 mb-3">
          <Link to={"/"}>
            Go back to Dashboard
            <span className="material-icons">arrow_forward</span>
          </Link>
        </h3>
      </div>
    </Col>
  );
};

export default NoMatch;
