import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Form, Table } from "react-bootstrap";

import { put, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const SitePermissioning = ({ member, organization, onSitesUpdated }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [memberSites, setMemberSites] = useState();
  const [organizationSites, setOrganizationSites] = useState();

  const fetchMemberSites = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let fetchOrganizationSites = get(`organizations/${organization.id}/sites`);
    let fetchMemberSites = get(`members/${member.id}/sites`, {
      params: { organizationId: organization.id },
    });

    Promise.all([fetchOrganizationSites.promise, fetchMemberSites.promise])
      .then((responses) => {
        setOrganizationSites(responses[0].data);
        setMemberSites(responses[1].data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fetchOrganizationSites, fetchMemberSites);
  }, [member.id, organization.id]);

  const updateMemberSites = (sites) => {
    setError(null);
    setIsLoading(true);

    const updateSites = put(`members/${member.id}/sites`, {
      organizationId: organization.id,
      siteIds: sites.map((site) => site.id),
    });

    updateSites.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setMemberSites([...sites]);
        onSitesUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchMemberSites();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchMemberSites]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!organizationSites) return <span />;

  return (
    <div className="border m-1 bg-white p-2 rounded">
      {organizationSites && !organizationSites.length ? (
        <span className="text-muted">
          There are currently no sites to show. Add sites to organization to
          assign access.
        </span>
      ) : (
        <Table responsive hover size="sm">
          <thead>
            <tr>
              <td>Site</td>
              <td className="text-end">Can Manage Activities</td>
            </tr>
          </thead>
          <tbody>
            {((site) => {
              let allSitesAccess =
                memberSites.length === organizationSites.length;
              return (
                <tr className="bg-light border-dark border-2">
                  <td>All Sites</td>
                  <td className="text-end">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      value={allSitesAccess}
                      checked={allSitesAccess}
                      onChange={(ev) => {
                        if (allSitesAccess) updateMemberSites([]);
                        else updateMemberSites([...organizationSites]);
                      }}
                    />
                  </td>
                </tr>
              );
            })()}
            {organizationSites.map((site) => {
              let canAcess =
                memberSites &&
                !!memberSites.find((item) => item.id === site.id);
              return (
                <tr key={site.id}>
                  <td>{site.name}</td>
                  <td className="text-end">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      value={canAcess}
                      checked={canAcess}
                      onChange={(ev) => {
                        if (canAcess)
                          updateMemberSites([
                            ...memberSites.filter(
                              (item) => item.id !== site.id
                            ),
                          ]);
                        else updateMemberSites([...memberSites, site]);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

SitePermissioning.propTypes = {
  member: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onSitesUpdated: PropTypes.func.isRequired,
};

export default SitePermissioning;
