import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SitePcafQodScoreChart.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";

const SitePcafQodScoreChart = ({ activitiesEmissions, year }) => {
  const el = useRef(null);

  useEffect(() => {
    const yearSummary = activitiesEmissions
      ?.filter((item) => Number.parseInt(item.year) === Number.parseInt(year))
      ?.sort((a, b) => (a.activityTypeTitle > b.activityTypeTitle ? 1 : -1));

    const option = {
      legend: [
        {
          name: "Emissions",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 1, color: "#b5c334" },
                { offset: 0, color: "#313695" },
              ],
              global: false,
            },
          },
        },
        { name: "PCAF Score" },
      ],
      tooltip: {
        trigger: "axis",
        name: "Emissions",
        formatter: (params) =>
          `<div style="margin: 0px 0 0;line-height:1.5;">${
            params[0].axisValueLabel
          }
          <br />
          ${params[0].marker} ${
            params[0].seriesName
          }  <strong style="float: right; margin-left: 1.2rem">${
            getFormattedEmission(params[0].value || 0) + " tCO₂e"
          }</strong>
          <br />
         <strong style="float: right; margin-left: 1.2rem">${getFormattedEmission(
           yearSummary.find(
             (summary) => summary.activityTypeTitle === params[0].axisValueLabel
           )?.emissionsPercentage || 0,
           2
         )}%</strong>
          <br />
          ${params[1].marker} ${
            params[1].seriesName
          }  <strong style="float: right; margin-left: 1.2rem">${
            params[1].value ? getFormattedEmission(params[1].value, 2) : "n/a"
          }</strong>
          </div>
          `,
        axisPointer: { type: "none" },
      },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      visualMap: {
        show: false,
        min: Math.min(...yearSummary.map((item) => item.emissions)),
        max: Math.max(...yearSummary.map((item) => item.emissions)),
        dimension: 1,
        inRange: {
          color: [
            "#b5c334",
            "#fac858",
            "#eb8146",
            "#5470c6",
            "#91cc75",
            // "#fac858",
            // "#ee6666",
            // "#73c0de",
            // "#3ba272",
            // "#fc8452",
            // "#9a60b4",
            // "#ea7ccc",
          ],
        },
      },
      xAxis: {
        type: "category",
        data: yearSummary.map((item) => item.activityTypeTitle),
        axisLabel: {
          rotate: 45,
          width: 100,
          overflow: "break",
        },
      },
      yAxis: [
        {
          type: "value",
          name: "Emissions",
          axisLabel: {
            formatter: (value) =>
              Intl.NumberFormat("en-US", { notation: "compact" }).format(
                value
              ) + " tCO₂e",
          },
        },
        {
          type: "value",
          name: "PCAF Score",
          scale: true,
          min: 0,
          max: 5,
          splitNumber: 5,
          axisLabel: {
            formatter: (value) => Intl.NumberFormat("en-US").format(value),
          },
        },
      ],
      series: [
        {
          name: "Emissions",
          type: "bar",
          data: yearSummary.map((summary) => {
            return summary ? summary.emissions : 0;
          }),
        },
        {
          name: "PCAF Score",
          yAxisIndex: 1,
          type: "line",
          tooltip: {
            valueFormatter: (value) => Intl.NumberFormat("en-US").format(value),
          },
          data: yearSummary.map((summary) => (summary ? summary.pcafScore : 0)),
          lineStyle: { color: "#27727b", opacity: 0 },
          symbol: "circle",
          symbolSize: 8,
          itemStyle: {
            borderWidth: 2,
            borderColor: "#27727b",
            opacity: 0.5,
          },
        },
      ],
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, activitiesEmissions, year]);

  return (
    <div className="SitePcafQodScoreChart">
      <div className="site-qod-score-chart-container me-auto" ref={el} />
    </div>
  );
};

SitePcafQodScoreChart.propTypes = {
  activitiesEmissions: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
};

export default SitePcafQodScoreChart;
