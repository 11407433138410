import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form } from "react-bootstrap";
import { getAllYearsBetweenDates } from "utils/dateUtils";
import { getFormattedEmission } from "utils/StringUtils";

import { get } from "utils/DeApi";

import SitePcafQodScoreChart from "./SitePcafScoreChart/SitePcafQodScoreChart";
import Loader from "components/Loader/Loader";

export default function SiteQodScoreSummary({ emissionsSummary = [{}], site }) {
  const defaultSummary = {
    qodScore: 0,
    qodActivitiesCount: 0,
    investmentActivitiesCount: 0,
  };

  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [sitePcafSummary, setSitePcafSummary] = useState([]);

  const recentYear = new Date().getFullYear() - 1;
  const [year, setYear] = useState(recentYear.toString());

  const [currentSummary, setCurrentSummary] = useState(
    emissionsSummary.find((item) => item.yearEnded === `${year}-12-31`) ||
      defaultSummary
  );

  const fetchSitePcafQodScoreSummary = useCallback(
    (siteID, yearEnded = recentYear.toString()) => {
      setIsLoading(true);
      setError();

      const sitePcafSummary = get(`/sites/${siteID}/pcaf-rollup`, {
        params: {
          yearEnded: `${yearEnded}-12-31`,
        },
      });

      sitePcafSummary.promise
        .then((response) => {
          setSitePcafSummary([...response.data]);
          setCurrentSummary({
            qodScore: response.summary?.totalPcafScore,
            qodActivitiesCount: response.summary?.pcafActivitiesCount,
            investmentActivitiesCount: response.summary?.activitiesCount,
          });

          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
          console.log(error);
        });
      subscribedPromises.current.push(sitePcafSummary);
    },
    []
  );

  useEffect(() => {
    fetchSitePcafQodScoreSummary(site.id);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [site.id, fetchSitePcafQodScoreSummary]);

  return (
    <>
      <Row>
        <Col lg={6} xs={12}>
          <h2>PCAF Data Quality Score</h2>
          <p>For Scope 3 Category 15 - Investments</p>
        </Col>
        <Col lg={6} xs={12}>
          <div className="ms-2 d-inline-flex float-end">
            {currentSummary && (
              <div className="mx-1 px-3 bg-success text-success bg-opacity-10">
                <span className="fs-1">
                  {getFormattedEmission(currentSummary.qodScore, 2)}
                </span>{" "}
                <small>
                  based on {currentSummary.qodActivitiesCount} out of{" "}
                  {currentSummary.investmentActivitiesCount} activities
                </small>
              </div>
            )}
            <div className="d-inline-flex align-items-center mx-1 align-center">
              <Form.Select
                aria-label="year-filter"
                size="md"
                value={year}
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setYear(selectedYear);
                  fetchSitePcafQodScoreSummary(site.id, selectedYear);
                }}
              >
                {getAllYearsBetweenDates().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        {!isLoading && sitePcafSummary ? (
          <SitePcafQodScoreChart
            activitiesEmissions={sitePcafSummary}
            year={year}
          />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

SiteQodScoreSummary.defaultProps = {
  emissionsSummary: [
    {
      qodScore: 0,
      qodActivitiesCount: 0,
      investmentActivitiesCount: 0,
    },
  ],
};

SiteQodScoreSummary.propTypes = {
  emissionsSummary: PropTypes.arrayOf(
    PropTypes.shape({
      qodScore: PropTypes.number,
      qodActivitiesCount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      investmentActivitiesCount: PropTypes.number,
    })
  ),
  site: PropTypes.object.isRequired,
};
