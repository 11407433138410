import { useState } from "react";
import { Form, Badge } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";

function InteractiveProject({ project, onProjectUpdate }) {
  const [scale, setScale] = useState(1);

  return (
    <div className="d-flex flex-row w-100 mb-3">
      <div>
        <span
          className="me-2 mt-1"
          style={{
            backgroundColor: project?.itemStyle?.color,
            width: 16,
            display: "block",
            height: 10,
          }}
        ></span>
      </div>
      <Form.Check
        type="Checkbox"
        checked={project.show}
        onChange={() =>
          onProjectUpdate({
            ...project,
            show: !project.show,
          })
        }
        id={`default-${project.id}`}
      />

      <span className="flex-fill mx-2">{project.name}</span>
      <div className="d-flex d-flex-row mx-2" style={{ width: 100 }}>
        <Form.Range
          style={{ width: 60 }}
          value={scale}
          step={0.5}
          min={0.5}
          max={2.5}
          onChange={(ev) =>
            setScale((prev) => {
              const range = ev.target.value;
              onProjectUpdate({
                ...project,
                initialInvestment: project.initialInvestment * range || "",
                annualSavings: project.annualSavings * range,
                disposalCost: project?.disposalCost * range || 0,
                annualCarbonEmissionReduction:
                  project?.annualCarbonEmissionReduction * range || 0,
                show: project.show,
              });

              return range;
            })
          }
          id={`range-${project.id}`}
        />
        <div style={{ width: 40 }}>
          <Badge className="float-end bg-opacity-25 text-dark ms-2 mt-1">
            x{scale}
          </Badge>
        </div>
      </div>
      <div>
        <Form.Control
          className="float-end"
          style={{ width: 100 }}
          size="sm"
          type="date"
          name="emissionSavingsStartDate"
          value={
            project.emissionSavingsStartDate
              ? moment(new Date(project.emissionSavingsStartDate)).format(
                  "YYYY-MM-DD"
                )
              : ""
          }
          onChange={(ev) =>
            onProjectUpdate({
              ...project,
              emissionSavingsStartDate: moment(
                new Date(ev.target.value)
              ).format("MM/DD/YYYY"),
            })
          }
        />
      </div>
    </div>
  );
}

InteractiveProject.propTypes = {
  project: PropTypes.object.isRequired,
  onProjectUpdate: PropTypes.func.isRequired,
};

export default InteractiveProject;
