import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./PortfolioOrganizationsChart.scss";
import * as echarts from "echarts";

const PortfolioOrganizationsChart = ({ organizations, onChartRendered }) => {
  const el = useRef(null);

  useEffect(() => {
    const emissionsData = organizations.map((org) => {
      if (org?.attributableEmissionsPercent) {
        return {
          value: org?.attributableEmissionsPercent || 0,
          name: org.name,
        };
      }
    });

    const option = {
      color: [
        "#b5c334",
        "#fac858",
        "#eb8146",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value
            ? Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(value || 0) + "%"
            : "0.00%",
      },
      legend: {
        show: false,
      },

      series: [
        {
          name: "",
          type: "pie",
          radius: "70%",
          center: ["50%", "55%"],
          data:
            emissionsData?.filter((data) => data)?.length !== 0
              ? emissionsData
              : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.8)",
            },
          },
          label: {
            position: "outer",
            alignTo: "none",
            textStyle: {
              fontSize: 8,
            },
            formatter: "{d}%: {b}",
          },
        },
      ],
    };
    const chart = echarts.init(el.current);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered && onChartRendered(src);
    });

    chart.setOption(option);

    return () => {
      chart.clear();
    };
  }, [el, organizations]);

  return (
    <div className="PortfolioOrganizationsChart">
      <div
        className="portfolio-organizations-chart-container me-auto"
        ref={el}
      />
    </div>
  );
};

PortfolioOrganizationsChart.defaultProps = {
  organizations: [],
};

PortfolioOrganizationsChart.propTypes = {
  organizations: PropTypes.array,
};

export default PortfolioOrganizationsChart;
