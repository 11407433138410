import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Modal, Button, Form } from "react-bootstrap";

import { destroy } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const DeleteActivity = ({ activity, factor, type, onActivityDeleted }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteActivity = () => {
    setError(null);
    setIsLoading(true);

    const activityPromise = destroy(`activities/${activity.id}`);

    activityPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        onActivityDeleted(activity);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="py-0"
        size="sm"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">delete</span>
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this activity?
          </Modal.Title>
        </Modal.Header>
        <Formik onSubmit={deleteActivity} initialValues={{}}>
          {({ handleSubmit }) => (
            <Form>
              <Modal.Body>
                <h4>{type.title}</h4>
                {factor ? (
                  <p>
                    Emissions Resource: <i>{factor.emissionResource}</i>
                    <br />
                    Quantity of Resource: <i>{activity.amount}</i> {factor.unit}
                  </p>
                ) : (
                  <p>
                    Emissions:{" "}
                    <i>
                      {activity.emissions} tCO<sub>2</sub>e
                    </i>
                  </p>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" variant="danger">
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

DeleteActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  onActivityDeleted: PropTypes.func.isRequired,
};

export default DeleteActivity;
