import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SiteReviewActivitiesTable from "./SiteReviewActivities";
import SiteReviewWorkflowManagement from "./SiteReviewWorkflow";

import { getAllYearsBetweenDates } from "utils/dateUtils";
import { get, post } from "utils/DeApi";

import "./SiteReview.scss";
import { put } from "utils/DeApi";

const SiteReview = ({ site, onSiteUpdated }) => {
  const [show, setShow] = useState(false);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [year, setYear] = useState((new Date().getFullYear() - 1).toString()); // initialize year to previous year
  const [activities, setActivities] = useState([]);
  const [activitiesVerified, setActivitiesVerified] = useState(false);

  const [siteReviewWorkflow, setSiteReviewWorkflow] = useState();

  const subscribedPromises = useRef([]);

  const [tabKey, setTabKey] = useState("verification");

  const handleClose = () => {
    onSiteUpdated();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const fetchActivities = useCallback(() => {
    if (show) {
      const activitiesPromise = get(
        `/sites/${site.id}/activities?precalc=true`,
        {
          params: {
            "filter[yearEnded]": year + "-12-31",
          },
        }
      );

      setIsLoading(true);
      setError("");
      activitiesPromise.promise
        .then((response) => {
          setActivities(response?.data);
          setIsLoading(false);
          setError("");
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
          console.error(error);
        });
      subscribedPromises.current.push(activitiesPromise);
    }
  }, [site.id, show, year]);

  const fetchAuditStatus = useCallback(() => {
    if (tabKey === "review") {
      const siteAuditPromise = get("/audit-activities", {
        params: {
          "filter[siteId]": site.id,
          "filter[year]": year,
        },
      });

      setIsLoading(true);
      setError("");
      siteAuditPromise.promise
        .then((response) => {
          if (response.data[0]) {
            setSiteReviewWorkflow(response.data[0]);
          } else {
            setSiteReviewWorkflow();
          }

          setIsLoading(false);
          setError("");
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
          console.error(error);
        });
      subscribedPromises.current.push(siteAuditPromise);
    }
  }, [site.id, tabKey, year]);

  const createSiteReviewWorkflow = (stage, status) => {
    const createSiteReviewWorkflow = post("/audit-activities", {
      siteId: site.id,
      year: `${year}-12-31`,
      stage: +stage,
      status: +status,
    });

    setIsLoading(true);
    setError("");
    createSiteReviewWorkflow.promise
      .then((response) => {
        console.log(response);
        setSiteReviewWorkflow(response.data);
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });
    subscribedPromises.current.push(createSiteReviewWorkflow);
  };

  const updateSiteReviewWorkflow = (stage, status, workflowID) => {
    const updateSiteReviewWorkflow = put(`/audit-activities/${workflowID}`, {
      siteId: site.id,
      year: `${year}-12-31`,
      stage: +stage,
      status: +status,
    });

    setIsLoading(true);
    setError("");
    updateSiteReviewWorkflow.promise
      .then((response) => {
        console.log(response);
        setSiteReviewWorkflow(response.data);
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });

    subscribedPromises.current.push(updateSiteReviewWorkflow);
  };

  const updateSiteReviewActivity = (activity, comment) => {
    const updateActivityObj = comment
      ? { flagComment: comment }
      : { isFlagged: !activity?.isFlagged };
    const updateSiteReviewActivity = put(
      `/activities/${activity?.id}/is-flagged`,
      updateActivityObj
    );

    setIsLoading(true);
    setError("");
    updateSiteReviewActivity.promise
      .then((response) => {
        fetchActivities();
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });

    subscribedPromises.current.push(updateSiteReviewActivity);
  };

  const handleManageReviewWorkflow = (stage, status, workflowID = "") => {
    // if workflowID exists, update
    if (!!workflowID) {
      updateSiteReviewWorkflow(stage, status, workflowID);
      return;
    }

    createSiteReviewWorkflow(stage, status);
  };

  useEffect(() => {
    fetchActivities();
    fetchAuditStatus();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities, fetchAuditStatus]);

  const handleFlagActivityAsOk = (activityId) => {
    setActivities([
      ...activities.map((activity) => {
        if (activity.id === activityId) {
          activity.isOk = true;
          activity.anomalyMessage = "Flagged as ok";
          storeFlagsInSessionStorage(activity);
        }
        return activity;
      }),
    ]);
  };

  const retrieveFlagsFromSessionStorage = () => {
    const sessionStorageKey = "flaggedActivities";
    const flaggedActivities = sessionStorage.getItem(sessionStorageKey);
    return flaggedActivities !== null ? JSON.parse(flaggedActivities) : {};
  };

  const storeFlagsInSessionStorage = (activity) => {
    const sessionStorageKey = "flaggedActivities";
    const flaggedActivities = sessionStorage.getItem(sessionStorageKey);
    if (flaggedActivities !== null) {
      const flaggedActivitiesData = JSON.parse(flaggedActivities);
      flaggedActivitiesData[activity.id] = {
        isOk: activity.isOk,
        anomalyMessage: activity.anomalyMessage,
      };
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(flaggedActivitiesData)
      );
    } else {
      const flaggedActivitiesData = {
        [activity.id]: {
          isOk: activity.isOk,
          anomalyMessage: activity.anomalyMessage,
        },
      };
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(flaggedActivitiesData)
      );
    }
  };

  const checkAnomaly = (activity) => {
    // Anomaly 1 - is emission factor updatedAt latest than activity updatedAt
    const activityUpdatedAt = new Date(activity.updatedAt);
    const activityEmissionFactorUpdatedAt = new Date(
      activity.emissionFactor?.updatedAt
    );
    const flaggedActivities = retrieveFlagsFromSessionStorage();
    const flaggedActivity = flaggedActivities[activity.id];

    if (!!flaggedActivity) {
      activity.isOk = flaggedActivity.isOk;
      activity.anomalyMessage = flaggedActivity.anomalyMessage;
    } else if (activityEmissionFactorUpdatedAt > activityUpdatedAt) {
      activity.isOk = false;
      activity.anomalyMessage =
        "Emission factor for this activity has been updated, please recalculate the emissions as needed.";
    }
    // Anomaly 2 - activity is a precal emission but no supporting documents attached
    else if (!activity.isActivity && !activity.attachments?.length) {
      activity.isOk = false;
      activity.anomalyMessage =
        "This is a user-calculated emission but has no supporting documents, please update as needed";
    } else {
      activity.isOk = true;
      activity.anomalyMessage = "Activity is ok";
    }

    return activity;
  };

  const checkAnomalies = (_activities) => {
    setIsLoading(true);

    const checkedActivities = _activities.map((activity) => {
      return checkAnomaly(activity);
    });

    setIsLoading(false);
    setActivities(checkedActivities);
  };

  const isYearActivitiesVerified = () => {
    setActivitiesVerified(
      activities?.length !== 0 &&
        activities.every((activity) => !activity?.isFlagged)
    );
  };
  useEffect(() => !!activities && isYearActivitiesVerified(), [activities]);

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={handleShow}
        size="sm"
        className="py-0 reviewBtn"
      >
        Review
      </Button>

      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleClose}
        size="lg"
        fullscreen
      >
        <Modal.Header
          closeButton
          className="text-white bg-primary"
          closeVariant="white"
        >
          <Modal.Title>
            <strong>
              Review Bottom-Up Inventory for <i>{site.name}</i>
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="activity-year" className="my-3">
            <Form.Label>Reporting year</Form.Label>
            <Form.Select
              aria-label="year-filter"
              size="sm"
              className="ps-3"
              value={year || "All Years"}
              onChange={(ev) => {
                let selectedYear = ev.target.value;
                setYear(selectedYear);
                setTabKey("verification");
                setActivities([]);
              }}
            >
              {getAllYearsBetweenDates().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Tabs
            defaultActiveKey="verification"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="mb-3"
          >
            <Tab eventKey="verification" title="Data verification">
              {error && <ErrorHandler error={error} />}
              {isLoading && <Loader />}
              {!!activities.length && !isLoading && (
                <SiteReviewActivitiesTable
                  activities={activities}
                  handleFlagActivityAsOk={handleFlagActivityAsOk}
                  handleClose={handleClose}
                  updateSiteReviewActivity={updateSiteReviewActivity}
                />
              )}
              {!activities.length && !isLoading && (
                <Alert variant="info">No data for this year</Alert>
              )}
            </Tab>
            {/* <Tab
              eventKey="review"
              title="Manage review workflow"
              disabled={!activitiesVerified}
            >
              {error && <ErrorHandler error={error} />}
              {isLoading && <Loader />}
              {!error && !isLoading && (
                <SiteReviewWorkflowManagement
                  year={year}
                  handleManageReviewWorkflow={handleManageReviewWorkflow}
                  siteReviewWorkflow={siteReviewWorkflow}
                />
              )}
            </Tab> */}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={handleClose}
            size="md"
            className="py-0"
          >
            Exit
          </Button>
          {/* {tabKey === "verification" && (
            <>
              {!activitiesVerified ? (
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip>
                      Please clear all the exceptions to start the review
                      workflow
                    </Tooltip>
                  }
                >
                  <span>
                    <Button
                      variant="primary"
                      onClick={() => setTabKey("review")}
                      size="md"
                      className="py-0"
                      disabled={!activitiesVerified}
                    >
                      <span className="material-icons-outlined md-18">
                        grading
                      </span>
                      Manage Review Workflow
                    </Button>
                  </span>
                </OverlayTrigger>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => setTabKey("review")}
                  size="md"
                  className="py-0"
                  disabled={!activitiesVerified}
                >
                  <span>
                    <span className="material-icons-outlined md-18">
                      grading
                    </span>{" "}
                    Manage Review Workflow
                  </span>
                </Button>
              )}
            </>
          )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

SiteReview.propTypes = {
  site: PropTypes.object.isRequired,
  onSiteUpdated: PropTypes.func.isRequired,
};

export default SiteReview;
