import InflationRates from "components/Organization/DataModels/InflationRates";
import InflationRatesUSEEIO from "components/Organization/DataModels/InflationRatesUSEEIO2018";

function getEmissionFromPercentageEquity(emission, percentageEquity) {
  return (emission * percentageEquity) / 100;
}

function getInflationRateFromYear(year, isUSEEIO2018Dataset = false) {
  if (isUSEEIO2018Dataset) {
    const yearInfo = InflationRatesUSEEIO.find((item) => item.year === year);
    return yearInfo ? yearInfo.inflationRate : "N/A";
  }
  const yearInfo = InflationRates.find((item) => item.year === year);
  return yearInfo ? yearInfo.inflationRate : "N/A";
}

function isNegative(num) {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
}

export {
  getEmissionFromPercentageEquity,
  getInflationRateFromYear,
  isNegative,
};
