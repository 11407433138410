import React, { useEffect, useState, useRef } from "react";
import { Table, Alert } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { useLocation } from "react-router-dom";
import LockUnlockComment from "./LockUnlockComment";
import LockUnlockHistory from "./LockUnlockHistory";
import { get } from "utils/DeApi";
import "./SiteLockUnlock.scss";

function SiteLockUnlock({
  site,
  organization,
  breadcrumbs,
  setSiteActYearsUnderReview,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [siteLockUnlockDetail, setSiteLockUnlockDetail] = useState([]);
  const [siteYearsUnderReview, setSiteYearsUnderReview] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [onSiteUpdate, setOnSiteUpdate] = useState(false);
  const subscribedPromises = useRef([]);

  const location = useLocation();

  const getSiteYearsUnderReview = (siteReviews) => {
    const siteYearsUnderReview = [];

    siteReviews.forEach((review) => {
      const latestStageOne = review.auditActivityHistory?.find(
        (hist) => hist.stage === 1
      );
      const latestStageTwo = review.auditActivityHistory?.find(
        (hist) => hist.stage === 2
      );
      const latestStageThree = review.auditActivityHistory?.find(
        (hist) => hist.stage === 3
      );

      if (
        latestStageOne?.status === 2 ||
        latestStageTwo?.status === 2 ||
        latestStageThree?.status === 2
      ) {
        siteYearsUnderReview.push(new Date(review.year).getFullYear());
      }
    });

    return siteYearsUnderReview;
  };

  const fetchAuditStatus = () => {
    const siteAuditPromise = get("/audit-activities", {
      params: {
        "filter[siteId]": site.id,
      },
    });

    setIsLoading(true);
    setError("");
    siteAuditPromise.promise
      .then((response) => {
        setSiteLockUnlockDetail(response?.data);
        setAvailableYears(response?.meta?.availableYears);
        setSiteYearsUnderReview(getSiteYearsUnderReview(response.data));
        setSiteActYearsUnderReview(getSiteYearsUnderReview(response.data));
        setOnSiteUpdate(false);
        setIsLoading(false);
        setError("");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });
    subscribedPromises.current.push(siteAuditPromise);
  };

  useEffect(() => {
    fetchAuditStatus();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [site.id, onSiteUpdate]);

  return (
    <div>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Lock - Unlock`,
              link: location.pathname,
              active: true,
            },
          ]}
        />
      </div>
      <h2>Lock/Unlock Inventory for {site?.name}</h2>
      <hr />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : (
        <div>
          {!!availableYears?.length ? (
            <div className="table-scroll-end">
              <Table size="sm" striped responsive>
                <thead>
                  <tr>
                    <th>Reporting Year</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {availableYears?.map((data) => (
                    <tr key={data}>
                      <td>{data}</td>
                      <td className="text-end">
                        <LockUnlockComment
                          year={data}
                          site={site}
                          organization={organization}
                          siteYearsUnderReview={siteYearsUnderReview}
                          setOnSiteUpdate={setOnSiteUpdate}
                        />{" "}
                        <LockUnlockHistory
                          year={data}
                          siteLockUnlockDetail={siteLockUnlockDetail}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
        </div>
      )}
    </div>
  );
}

export default SiteLockUnlock;
