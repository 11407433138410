import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import moment from "moment";

const PortfolioOrganizationDelete = ({
  organization,
  emissionYear,
  portfolio,
  onOrganizationDeleted,
  organizationTenures,
  setIsDeleted,
  fetchOrganizationTenure,
  setFieldValue,
  organizationDetail,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup
      .string()
      .oneOf(
        [organizationDetail ? organizationDetail?.name : organization?.name],
        "Must match organization name"
      )
      .required("Must match organization name"),
  });

  const deleteOrganizationFromPortfolio = () => {
    setError(null);
    setIsLoading(true);
    if (organizationTenures) {
      const portfolioPromise = destroy(
        `portfolios/${portfolio.id}/organizations/${
          organizationDetail ? organizationDetail?.id : organization?.id
        }`,
        {
          deletingYear: moment(organization?.addedAt, "YYYY").year(),
          pivotId: organization.pivotId,
          deleteAll: 0,
        }
      );
      portfolioPromise.promise
        .then(() => {
          organizationTenures?.length === 1 && onOrganizationDeleted();
          setIsDeleted(true);
          handleClose();
          fetchOrganizationTenure(setFieldValue);
          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    } else {
      const portfolioPromise = destroy(
        `portfolios/${portfolio.id}/organizations/${organization.id}`,
        {
          deletingYear: emissionYear,
          pivotId: organization.pivotId,
          deleteAll: 1,
        }
      );
      portfolioPromise.promise
        .then((response) => {
          setError(null);
          setIsLoading(false);
          onOrganizationDeleted(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant={organizationTenures ? "light" : "outline-danger"}
        className={organizationTenures ? "btn-sm py-0" : "py-0 ms-1"}
        title="Remove organization from portfolio"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">delete</span>
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        className={
          organizationTenures
            ? "d-flex justify-content-center align-items-center"
            : ""
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Remove Organization{" "}
            <b>
              <i>
                {organizationDetail
                  ? organizationDetail?.name
                  : organization?.name}
              </i>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ name: "" }}
          onSubmit={(_) => deleteOrganizationFromPortfolio()}
          validateOnMount
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <p>
                  Are you sure that you would like to remove{" "}
                  <b>
                    <i>
                      {organizationDetail
                        ? organizationDetail?.name
                        : organization?.name}
                    </i>
                  </b>{" "}
                  from{" "}
                  <b>
                    <i>{portfolio.name}</i>
                  </b>{" "}
                  {organizationDetail ? (
                    <>
                      for reporting year{" "}
                      <b>
                        <i>{moment(organization?.addedAt, "YYYY").year()}</i>
                      </b>
                    </>
                  ) : (
                    <>
                      starting from{" "}
                      <b>
                        <i>{emissionYear}</i>
                      </b>
                    </>
                  )}
                  ?
                </p>

                <Form.Group controlId="name" className="mt-3 mb-3">
                  <Form.Label>
                    Please type the name of the organization to confirm.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter organization's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      organizationDetail
                        ? values.name === organizationDetail?.name
                        : values.name === organization?.name
                    }
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  size="sm"
                  variant="danger"
                  disabled={!isValid}
                >
                  Remove
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PortfolioOrganizationDelete.propTypes = {
  organization: PropTypes.object.isRequired,
  onOrganizationDeleted: PropTypes.func.isRequired,
};

export default PortfolioOrganizationDelete;
