import React, { useState, useContext, Fragment } from "react";
import { Modal, Button, Form, NavDropdown, Badge } from "react-bootstrap";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { AccountContext } from "contexts/AccountProvider";

const DataLink = () => {
  const [show, setShow] = useState(false);

  const account = useContext(AccountContext);
  const DE_URL = process.env.REACT_APP_DE_ENDPOINT;

  function handleCloseModal() {
    setShow(false);
  }

  function handleShowModal() {
    setShow(true);
  }

  return (
    <Fragment>
      <NavDropdown.Item className="" onClick={handleShowModal}>
        <div className="d-flex align-items-center">
          Data Link{" "}
          <small>
            <Badge className="ms-1" bg="danger">
              BETA
            </Badge>
          </small>
        </div>
      </NavDropdown.Item>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex">
              Data Link{" "}
              <small>
                <Badge className="ms-1" bg="danger">
                  BETA
                </Badge>
              </small>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CopyLink link={`${DE_URL}/accounts/${account.id}/activities`} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

function CopyLink({ link }) {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState();

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(link)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
      })
      .catch((err) => {
        setError(err);
      });
  };
  return (
    <div>
      <Form.Group className="d-flex">
        <Form.Control
          className="w-75"
          type="text"
          readOnly
          disabled
          value={link}
        />
        <Button
          onClick={handleCopyClick}
          size="sm"
          className="mx-2"
          variant="secondary"
        >
          {isCopied ? "Copied!" : "Copy"}
        </Button>
      </Form.Group>

      {error && <ErrorHandler error={error} />}
    </div>
  );
}

export default DataLink;
