import { useContext } from "react";
import { UserContext } from "contexts/UserProvider";

function UserCategories() {
  const user = useContext(UserContext);

  const filteredCategories = user?.categories?.filter((category) => {
    return category?.groups.some(
      (group) => group?.groupName === "Analytics Segmentation"
    );
  });

  const categoryName = filteredCategories?.map((item) => item.categoryName);
  return categoryName;
}

export default UserCategories;
