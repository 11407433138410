import React from "react";
import { Row, Col } from "react-bootstrap";

function CopyRight(props) {
  return (
    <Row>
      <Col xs={12} className="text-center mt-2">
        <small>Copyright © {new Date().getFullYear()} ERM</small>
      </Col>
    </Row>
  );
}
export default CopyRight;
