const REPORTING_METHODS = {
  /* 
  alias is for the name this reporting method used to be called before 
  GET /emission-factors API needs a type parameter,
  This type parameter is used to specify if the returned emission-factors are for which reporting method
  */
  customEF: "Custom EF",
  customEFAlias: "emission",
  directUserInput: "Direct User Input",
  directUserInputAlias: "emission",
  standardEF: "Standard EF",
  standardEFAlias: "activity",
};

REPORTING_METHODS[REPORTING_METHODS.directUserInput] =
  REPORTING_METHODS.directUserInputAlias;
REPORTING_METHODS[REPORTING_METHODS.standardEF] =
  REPORTING_METHODS.standardEFAlias;
REPORTING_METHODS[REPORTING_METHODS.customEF] = REPORTING_METHODS.customEFAlias;

export { REPORTING_METHODS };
